/** @format */

import {
  EllipsisVerticalIcon,
  LifebuoyIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { ENUM_ACCOUNT_TAG_TYPE } from "@prisma/client";
import { usePlatformDetect } from "@roadflex/react-hooks";
import {
  AccountTagType,
  ChildFeatureType,
  FleetExtended,
  IdListInputType,
  TabsType,
} from "@roadflex/types";
import { getCookie, setCookie } from "@roadflex/web-lib";
import classNames from "classnames";
import { FormikErrors } from "formik";
import { useRouter } from "next/router";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable, DataTableRowToggleParams } from "primereact/datatable";
import { Menu } from "primereact/menu";
import { createRef, FormEvent, useEffect, useRef, useState } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { Toast } from "../../../src/toast-message/toast";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { nameFilterTemplate } from "../../datatable";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";
import { AssignAccountTagModal } from "../../modals";
import { FuelCardComponentPermissionGuard } from "../../permission-guard";
import UploadVehiclesFile from "./upload-vehicles-file-modal";

interface VehiclesUIProps {
  vehiclesList: FleetExtended[];
  archivedVehicleList: FleetExtended[];
  getVehiclesLoading: boolean;
  getVehiclesRefetch: () => void;
  deleteVehiclesHandleSubmit: (
    e?: FormEvent<HTMLFormElement> | undefined,
  ) => void;
  deleteVehiclesSetValues: (
    fields: React.SetStateAction<IdListInputType>,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<unknown>>;
  joyride: boolean;
  setJoyride: (value: boolean) => void;
  steps: Step[];
  tabs: TabsType[];
  tabOpen: number;
  setTabOpen: (value: number) => void;
  maxVehicles: number;
  childFeatures: ChildFeatureType[];
}
export const VehiclesUI = ({
  vehiclesList,
  archivedVehicleList,
  getVehiclesLoading,
  getVehiclesRefetch,
  deleteVehiclesHandleSubmit,
  deleteVehiclesSetValues,
  steps,
  joyride,
  setJoyride,
  tabs,
  tabOpen,
  setTabOpen,
  maxVehicles,
  childFeatures,
}: VehiclesUIProps) => {
  const [expandedRows, setExpandedRows] = useState<FleetExtended[]>([]);
  const [uploadVehiclesFilePopup, setUploadVehiclesFilePopup] = useState(false);
  const [openAssignAccountTagModal, setOpenAssignAccountTagModal] =
    useState(false);
  const [selectedAccountTags, setSelectedAccountTags] = useState<
    AccountTagType[]
  >([]);
  const [itemName, setItemName] = useState<string>("");
  const [itemId, setItemId] = useState<string>("");

  const [vehiclesFilters, setVehiclesFilters] = useState({
    fleetName: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  });

  const ACCOUNT_TAGS_COLOR = [
    "bg-slate-200",
    "bg-red-200",
    "bg-amber-200",
    "bg-green-200",
    "bg-teal-200",
    "bg-gray-200",
    "bg-purple-200",
    "bg-blue-200",
    "bg-lime-200",
    "bg-orange-200",
    "bg-sky-200",
    "bg-pink-200",
  ];

  const access = {
    ImportVehicleAccess:
      childFeatures.find(
        (item) => item.name === "VEHICLE_PAGE_IMPORT_VEHICLES",
      ) !== undefined,
  };

  const { isMobile } = usePlatformDetect();
  const router = useRouter();
  const vehicleMenuRefs = useRef<(React.RefObject<Menu> | null)[]>([]);
  const archivedVehicleMenuRefs = useRef<(React.RefObject<Menu> | null)[]>([]);
  vehicleMenuRefs.current = vehiclesList.map(
    (_, i) => vehicleMenuRefs.current[i] ?? createRef(),
  );
  archivedVehicleMenuRefs.current = archivedVehicleList.map(
    (_, i) => archivedVehicleMenuRefs.current[i] ?? createRef(),
  );

  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setJoyride(true);
  };
  useEffect(() => {
    const vehiclesGuide = getCookie("vehiclesGuide");
    if (!vehiclesGuide) {
      setJoyride(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleJoyrideCallback = (callbackData: CallBackProps) => {
    const { status, type } = callbackData;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setJoyride(false);
    }
    if (status === STATUS.READY) {
      setCookie("vehiclesGuide", "true", 30);
    }
  };

  const accountTagTemplate = (rowData: FleetExtended) => {
    return (
      <div className="flex items-center justify-between gap-2 max-w-[250px]">
        <div className="flex flex-wrap gap-1">
          {rowData.accountTags && rowData.accountTags.length > 0 ? (
            rowData.accountTags.map((tag) => {
              const index = Number(tag?.colorCode) || 0;
              const length = ACCOUNT_TAGS_COLOR.length;
              const colorCode = ACCOUNT_TAGS_COLOR[index % length];

              return (
                <span
                  key={tag.id}
                  className={classNames(
                    colorCode,
                    "rounded-md px-1.5 py-0.5 whitespace-nowrap text-ellipsis overflow-hidden",
                  )}
                  title={tag.title}
                >
                  {tag.title}
                </span>
              );
            })
          ) : (
            <span className="text-gray-500">-</span>
          )}
        </div>
        <Button
          variant={ButtonVariant.Transparent}
          size={ButtonSize.Wrap}
          className="flex items-center flex-shrink-0 w-4 h-4"
          onClick={() => {
            setSelectedAccountTags(rowData?.accountTags || []);
            setItemId(rowData.id);
            setItemName(rowData.fleetName);
            setOpenAssignAccountTagModal(true);
          }}
        >
          <PencilIcon className="w-4 h-4" />
        </Button>
      </div>
    );
  };

  const rowExpansionTemplate = (data: FleetExtended) => {
    return (
      <div className="max-w-sm p-4 mx-auto bg-white border border-gray-300 rounded-md shadow-lg">
        <div className="flex flex-col gap-2 text-sm text-gray-600">
          <div>License Plate: {data?.fleetLicensePlate || "-"} </div>
          <div>VIN: {data?.vin || "-"} </div>
          <div>Make: {data?.make || "-"} </div>
          <div>Model: {data?.model || "-"} </div>
          <div>Year: {data?.year || "-"} </div>
          <div>Provision State: {data?.provisionState || "-"} </div>
          <div>Odometer: {data?.odometer || "-"} </div>
          <div>Prompt Id: {data?.promptCode || "-"} </div>
          {data?.status !== "DELETED" ? (
            <>
              <div className="flex items-center gap-1">
                Account Tags: {accountTagTemplate(data)}
              </div>
              <div className="flex flex-row justify-between gap-4 mt-2">
                <Button
                  size={ButtonSize.AppSize}
                  variant={ButtonVariant.Red}
                  onClick={() => {
                    deleteVehiclesSetValues({
                      idList: [data.id],
                    });
                    deleteVehiclesHandleSubmit();
                  }}
                >
                  Archive
                </Button>
                <Button
                  size={ButtonSize.AppSize}
                  variant={ButtonVariant.AppOrange}
                  onClick={() => {
                    router.push(`/vehicles/${data.id}`);
                  }}
                >
                  Edit
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="flex justify-between text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        <div className="">{`Vehicles`.toUpperCase()}</div>
        <div className="flex items-center justify-end rounded-md md:mt-0 step-4">
          <div>
            <Button
              onClick={handleClickStart}
              variant={ButtonVariant.Transparent}
              size={ButtonSize.Wrap}
            >
              <LifebuoyIcon
                className="flex-shrink-0 w-4 h-4"
                aria-hidden="true"
              />
              <span className="px-1 text-xs italic">Help Center</span>
            </Button>
          </div>
        </div>
      </div>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={joyride}
        scrollToFirstStep
        showProgress
        scrollOffset={76}
        disableCloseOnEsc
        disableOverlayClose
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: "#000000",
          },
        }}
      />
      <div className="p-4 mt-5 overflow-hidden bg-white rounded-md shadow md:p-8 md:mt-0">
        <div className="flex flex-row justify-end w-full">
          <div className="flex items-center gap-4">
            {!isMobile() && (
              <FuelCardComponentPermissionGuard
                type="button"
                featurePermission={access.ImportVehicleAccess}
                messageType="enterprise"
              >
                <Button
                  size={ButtonSize.AppSize}
                  variant={ButtonVariant.AppOrange}
                  onClick={() => {
                    setUploadVehiclesFilePopup(true);
                  }}
                  className="flex flex-row items-center gap-2 p-2 text-sm step-2"
                >
                  Import Vehicles{" "}
                  <span className="font-normal text-yellow-600">
                    (Enterprise)
                  </span>
                </Button>
              </FuelCardComponentPermissionGuard>
            )}
            <Button
              size={ButtonSize.AppSize}
              variant={ButtonVariant.AppOrange}
              onClick={() => {
                if (vehiclesList.length >= maxVehicles) {
                  Toast({
                    type: "error",
                    message: `A maximum of ${maxVehicles} vehicles allowed for your account`,
                  });
                } else {
                  router.push("/vehicles/new");
                }
              }}
              className="flex flex-row items-center gap-2 p-2 text-sm step-2"
            >
              Add Vehicle
            </Button>
          </div>
        </div>
        <div className="border-b border-brown-500">
          <nav className="flex -mb-px space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tabOpen === tab.id
                    ? "border-black text-black"
                    : "border-transparent hover:border-black",
                  "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                )}
                onClick={(e) => {
                  e.preventDefault();
                  setTabOpen(tab.id);
                }}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
        <div className={`${tabOpen === 1 ? `block` : `hidden`} mt-4 md:mt-6`}>
          <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
            <DataTable
              pageLinkSize={isMobile() ? 2 : 3}
              size="small"
              stripedRows
              paginator
              rows={10}
              dataKey="id"
              sortField="fleetName"
              sortOrder={1}
              paginatorTemplate={newPaginationTemplate}
              responsiveLayout={isMobile() ? "scroll" : "scroll"}
              emptyMessage="No available records"
              className="min-w-full w-fit custom-table"
              style={{
                fontFamily: "Inter",
              }}
              loading={getVehiclesLoading}
              value={vehiclesList}
              rowsPerPageOptions={[10, 20, 50]}
              filters={vehiclesFilters}
              rowExpansionTemplate={rowExpansionTemplate}
              expandedRows={expandedRows}
              onRowToggle={(e: DataTableRowToggleParams) =>
                setExpandedRows(e.data as FleetExtended[])
              }
            >
              <Column
                filter
                sortable
                showFilterMenuOptions={false}
                showFilterMatchModes={false}
                filterMenuStyle={{
                  width: "14rem",
                }}
                filterPlaceholder="Search by vehicle name"
                field="fleetName"
                header="Vehicle Name"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                filterMenuClassName="custom-filter"
                filterElement={nameFilterTemplate}
              />
              <Column
                sortable
                filterPlaceholder="Search by vehicle name"
                field="fleetLicensePlate"
                header="License Plate"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
              />
              {!isMobile() && (
                <Column
                  sortable
                  field="vin"
                  header="VIN"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  sortable
                  field="make"
                  header="Make"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  sortable
                  field="model"
                  header="Model"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  sortable
                  field="year"
                  header="Year"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  sortable
                  filterPlaceholder="Search by vehicle name"
                  field="provisionState"
                  header="Provision State"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  header="Account Tags"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={accountTagTemplate}
                />
              )}
              {!isMobile() && (
                <Column
                  sortable
                  filterPlaceholder="Search by vehicle name"
                  field="odometer"
                  header="Odometer"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  field="promptCode"
                  header="Prompt Id"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  headerStyle={{ width: "3em" }}
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base step-3"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(e, props) => (
                    <div className="relative flex">
                      <Menu
                        model={[
                          {
                            label: "Edit",
                            icon: "pi pi-fw pi-pencil",
                            command: () => {
                              router.push(`/vehicles/${e.id}`);
                            },
                          },
                          {
                            label: "Archive",
                            icon: "pi pi-fw pi-trash",
                            command: () => {
                              deleteVehiclesSetValues({
                                idList: [e.id || []],
                              });
                              deleteVehiclesHandleSubmit();
                            },
                          },
                        ]}
                        popup
                        ref={vehicleMenuRefs.current[props.rowIndex]}
                      />
                      <Button
                        variant={ButtonVariant.Transparent}
                        className="!p-0"
                        onClick={(event) =>
                          vehicleMenuRefs.current[
                            props.rowIndex
                          ]?.current?.toggle(event)
                        }
                      >
                        <EllipsisVerticalIcon className="w-5"></EllipsisVerticalIcon>
                      </Button>
                    </div>
                  )}
                ></Column>
              )}
              {isMobile() && (
                <Column
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  expander
                  style={{ width: "3em" }}
                />
              )}
            </DataTable>
          </div>
        </div>
        <div className={`${tabOpen === 2 ? `block` : `hidden`} mt-4 md:mt-6`}>
          <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
            <DataTable
              pageLinkSize={10}
              size="small"
              stripedRows
              paginator
              rows={10}
              dataKey="id"
              sortField="fleetName"
              sortOrder={1}
              paginatorTemplate={newPaginationTemplate}
              responsiveLayout={isMobile() ? "scroll" : "scroll"}
              emptyMessage="No available records"
              className="min-w-full w-fit custom-table"
              style={{
                fontFamily: "Inter",
              }}
              loading={getVehiclesLoading}
              value={archivedVehicleList}
              rowsPerPageOptions={[10, 20, 50]}
              rowExpansionTemplate={rowExpansionTemplate}
              expandedRows={expandedRows}
              onRowToggle={(e: DataTableRowToggleParams) =>
                setExpandedRows(e.data as FleetExtended[])
              }
            >
              <Column
                sortable
                filterPlaceholder="Search by vehicle name"
                field="fleetName"
                header="Vehicle Name"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
              />
              <Column
                sortable
                filterPlaceholder="Search by vehicle name"
                field="fleetLicensePlate"
                header="License Plate"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
              />
              {!isMobile() && (
                <Column
                  sortable
                  field="vin"
                  header="VIN"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  sortable
                  field="make"
                  header="Make"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  sortable
                  field="model"
                  header="Model"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  sortable
                  field="year"
                  header="Year"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  sortable
                  filterPlaceholder="Search by vehicle name"
                  field="provisionState"
                  header="Provision State"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  sortable
                  filterPlaceholder="Search by vehicle name"
                  field="odometer"
                  header="Odometer"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  field="promptCode"
                  header="Prompt Id"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  headerStyle={{ width: "3em" }}
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base step-3"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(e, props) => (
                    <div className="relative flex">
                      <Menu
                        model={[
                          {
                            label: "View",
                            icon: "pi pi-fw pi-pencil",
                            command: () => {
                              router.push(`/vehicles/${e.id}`);
                            },
                          },
                        ]}
                        popup
                        ref={archivedVehicleMenuRefs.current[props.rowIndex]}
                      />
                      <Button
                        variant={ButtonVariant.Transparent}
                        className="!p-0"
                        onClick={(event) =>
                          archivedVehicleMenuRefs.current[
                            props.rowIndex
                          ]?.current?.toggle(event)
                        }
                      >
                        <EllipsisVerticalIcon className="w-5"></EllipsisVerticalIcon>
                      </Button>
                    </div>
                  )}
                ></Column>
              )}
              {isMobile() && (
                <Column
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  expander
                  style={{ width: "3em" }}
                />
              )}
            </DataTable>
          </div>
        </div>
      </div>

      {openAssignAccountTagModal && (
        <AssignAccountTagModal
          setOpen={setOpenAssignAccountTagModal}
          open={openAssignAccountTagModal}
          accountTagType={ENUM_ACCOUNT_TAG_TYPE.VEHICLE}
          currentAccountTags={selectedAccountTags}
          itemName={itemName}
          itemId={itemId}
          refetchData={getVehiclesRefetch}
        />
      )}

      {uploadVehiclesFilePopup && (
        <UploadVehiclesFile
          uploadVehiclesFilePopup={uploadVehiclesFilePopup}
          setUploadVehiclesFilePopup={setUploadVehiclesFilePopup}
          refetchVehicles={getVehiclesRefetch}
        ></UploadVehiclesFile>
      )}
    </div>
  );
};
