import { dollarFormatter } from "@roadflex/constants";
import { AuthorizationType } from "@roadflex/types";
import { convertISOStringToDate, humanize } from "@roadflex/utils";

export const authorizationCSVMapper = (authorizations: AuthorizationType[]) => {
  const authorization = authorizations.map((transaction) => {
    return {
      authorizationDate: convertISOStringToDate(
        transaction?.authorizationDate?.toString(),
        false,
        false,
        true,
      ),
      timezone: new Date().toTimeString().slice(9),
      driverName: transaction.driverName ? transaction.driverName : "",
      vehicleName: transaction.vehicleName ? transaction.vehicleName : "",
      vin: transaction.vehicleVIN ? transaction.vehicleVIN : "",
      location: `${
        transaction?.stripeResponse?.merchant_data?.city
          ? `${transaction?.stripeResponse?.merchant_data?.city},`
          : ""
      } ${transaction?.merchantState ? transaction.merchantState : ""} ${
        transaction?.merchantPostCd ? transaction.merchantPostCd : ""
      } `,
      lastFour: `${transaction.card.lastFour}`,
      metaData: `${transaction.card.metaData}`
        ? (
            transaction.card.metaData as unknown as {
              policyName: string;
            }
          )?.policyName
        : "",
      merchantName: transaction.merchantName,
      merchantCategory: humanize(
        transaction.stripeResponse?.merchant_data?.category || "",
      ),
      amount: dollarFormatter(transaction.authorizationAmount / 100),
      status: "Pending",
      memo: transaction?.memo || "",
      transactionTagId: transaction?.transactionTagId || "",
      transactionTagName: transaction?.transactionTagName || "",
      transactionTagDescription: transaction?.transactionTagDescription || "",
      cardTagId: transaction?.cardTagId || "",
      cardTagName: transaction?.cardTagName || "",
      cardTagDescription: transaction?.cardTagDescription || "",
      driverTagId: transaction?.driverTagId || "",
      driverTagName: transaction?.driverTagName || "",
      driverTagDescription: transaction?.driverTagDescription || "",
      vehicleTagId: transaction?.vehicleTagId || "",
      vehicleTagName: transaction?.vehicleTagName || "",
      vehicleTagDescription: transaction?.vehicleTagDescription || "",
      receipts: transaction?.receipts,
      policyName: transaction?.policyName || "",
      odometerReading: transaction?.extraInfo?.odometerReading || "",
    };
  });
  return authorization;
};
