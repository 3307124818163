/** @format */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ENUM_FUEL_TYPE } from "@prisma/client";
import {
  READ_APP_CARD_DATA,
  READ_AUTHORIZATION_LIST_PAGINATION,
  READ_BLOCKED_MERCHANTS_BY_MERCHANT_ID_FROM_ACCOUNT,
  READ_TRANSACTION_DASHBOARD_DETAILS,
  READ_TRANSACTION_LIST_PAGINATION,
  TRANSACTION_FILTER_OPTIONS,
  UPDATE_BLOCK_MERCHANTS_STATES,
  UPDATE_BLOCK_MERCHANT_BY_MERCHANT_ID,
  UPDATE_TRANSACTION_MEMO,
} from "@roadflex/graphql";
import {
  AuthorizationType,
  TransactionDashboadDetailsType,
  TransactionType,
} from "@roadflex/types";
import { useFormik } from "formik";
import { DataTablePFSEvent } from "primereact/datatable";
import { useState } from "react";

type Values = {
  memo: string;
  transactionId: string;
  transactionTagIds?: string[];
  cardTagIds?: string[];
  driverTagIds?: string[];
  vehicleTagIds?: string[];
  type: string;
};
type useUpdateTransactionMemoProps = {
  initialValues: Values;
  onSubmit: (val: any, err: any) => void;
};

type useUpdateBlockedMerchantsStatesProps = {
  initialValues: {
    blockedMerchants: string;
    blockedStates: string;
  };
  onSubmit: (val: any, err: any) => void;
};

export const useReadTransactionDashboard = () => {
  const {
    data: transactionDashboardData,
    refetch: transactionDashboardRefetch,
    loading: transactionDashboardLoading,
  } = useQuery<{
    readTransactionDashboardDetails: {
      transactionDashboardDetails: TransactionDashboadDetailsType;
      code: string;
      message: string;
    };
  }>(READ_TRANSACTION_DASHBOARD_DETAILS, {
    fetchPolicy: "no-cache",
  });

  return {
    transactionDashboardData,
    transactionDashboardRefetch,
    transactionDashboardLoading,
  };
};

export const useReadAuthorizations = (
  authorizationLazyParams: DataTablePFSEvent,
  approvalStatus: boolean,
  authorizationStatus: string,
  filterCardTags?: string[],
) => {
  const {
    data: authorizationData,
    refetch: authorizationRefetch,
    loading: authorizationLoading,
  } = useQuery<{
    readAuthorizationList: {
      code: string;
      message: string;
      authorizations: AuthorizationType[];
      totalCount: number;
    };
  }>(READ_AUTHORIZATION_LIST_PAGINATION, {
    fetchPolicy: "no-cache",
    variables: {
      data: {
        ...authorizationLazyParams,
        approvalStatus,
        authorizationStatus,
        filterCardTags,
      },
    },
  });
  return {
    authorizationData,
    authorizationRefetch,
    authorizationLoading,
  };
};

export const useReadAuthorizationsExport = (
  authorizationLazyParams: DataTablePFSEvent,
  approvalStatus: boolean,
  authorizationStatus: string,
  filterCardTags?: string[],
) => {
  const {
    data: authorizationDataExport,
    refetch: authorizationRefetchExport,
    loading: authorizationLoadingExport,
  } = useQuery<{
    readAuthorizationList: {
      code: string;
      message: string;
      authorizations: AuthorizationType[];
      totalCount: number;
    };
  }>(READ_AUTHORIZATION_LIST_PAGINATION, {
    fetchPolicy: "no-cache",
    variables: {
      data: {
        ...authorizationLazyParams,
        approvalStatus,
        authorizationStatus,
        filterCardTags,
      },
    },
  });
  return {
    authorizationDataExport,
    authorizationRefetchExport,
    authorizationLoadingExport,
  };
};

export const useReadTransactions = (
  transactionLazyParams: DataTablePFSEvent,
  receiptUploaded: boolean,
  receiptRequire: boolean,
  flagged: boolean,
  filterCardTags?: string[],
  transactionTags?: string[],
  cardTags?: string[],
  driverTags?: string[],
  vehicleTags?: string[],
) => {
  const {
    data: transactionData,
    refetch: transactionRefetch,
    loading: transactionLoading,
  } = useQuery<{
    readTransactionList: {
      code: string;
      message: string;
      transactions: TransactionType[];
      totalCount: number;
    };
  }>(READ_TRANSACTION_LIST_PAGINATION, {
    fetchPolicy: "no-cache",
    variables: {
      data: {
        ...transactionLazyParams,
        receiptUploaded,
        receiptRequire,
        flagged,
        filterCardTags,
        transactionTags,
        cardTags,
        driverTags,
        vehicleTags,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
  });
  return {
    transactionData,
    transactionRefetch,
    transactionLoading,
  };
};

export const useReadTransactionsExport = (
  transactionLazyParams: DataTablePFSEvent,
  receiptUploaded: boolean,
  receiptRequire: boolean,
  flagged: boolean,
  filterCardTags?: string[],
  transactionTags?: string[],
  cardTags?: string[],
  driverTags?: string[],
  vehicleTags?: string[],
) => {
  const {
    data: transactionDataExport,
    refetch: transactionRefetchExport,
    loading: transactionLoadingExport,
  } = useQuery<{
    readTransactionList: {
      code: string;
      message: string;
      transactions: TransactionType[];
      totalCount: number;
    };
  }>(READ_TRANSACTION_LIST_PAGINATION, {
    fetchPolicy: "no-cache",
    variables: {
      data: {
        ...transactionLazyParams,
        receiptUploaded,
        receiptRequire,
        flagged,
        filterCardTags,
        transactionTags,
        cardTags,
        driverTags,
        vehicleTags,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
  });
  return {
    transactionDataExport,
    transactionRefetchExport,
    transactionLoadingExport,
  };
};

export const useReadFilterOptions = () => {
  const { data: filterOptions, loading } = useQuery<{
    transactionFilterOptions: {
      code: string;
      message: string;
      cards: {
        id: string;
        createdAt: Date;
        lastFour: string;
      }[];
      drivers: {
        id: string;
        createdAt: Date;
        name: string;
        isPhoneVerified?: boolean;
      }[];
      vehicles: {
        id: string;
        createdAt: Date;
        fleetName: string;
        tankCapacity: number;
        fuelType: ENUM_FUEL_TYPE;
        eldOrgName: string;
      }[];
    };
  }>(TRANSACTION_FILTER_OPTIONS, { fetchPolicy: "no-cache" });
  return {
    filterOptions,
    loading,
  };
};

export const useUpdateBlockedMerchantsStates = ({
  onSubmit,
  initialValues,
}: useUpdateBlockedMerchantsStatesProps) => {
  const [UpdateBlockedMerchantsFn] = useMutation(UPDATE_BLOCK_MERCHANTS_STATES);

  const {
    handleChange: handleChangeBlockedMerchants,
    handleSubmit: blockedMerchantsHandleSubmit,
    values: blockedMerchantsValues,
    isSubmitting: blockedMerchantsIsSubmitting,
    resetForm: blockedMerchantsResetForm,
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        const data = { ...values };
        const res = await UpdateBlockedMerchantsFn({
          variables: { data },
        });
        onSubmit(res, null);
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });
  return {
    blockedMerchantsValues,
    handleChangeBlockedMerchants,
    blockedMerchantsHandleSubmit,
    blockedMerchantsIsSubmitting,
    blockedMerchantsResetForm,
  };
};

export const useUpdateBlockMerchantByNetworkId = () => {
  const [blockMerchantByNetworkIdFn, { loading }] = useMutation<
    {
      updateBlockedMerchantsNetworkIds: {
        code: string;
        message: string;
      };
    },
    {
      data: {
        merchantNetworkId: { merchantName: string; networkId: string };
        blockLevel: string;
        cardsList: string[];
        action: string;
      };
    }
  >(UPDATE_BLOCK_MERCHANT_BY_MERCHANT_ID, {
    refetchQueries: [READ_APP_CARD_DATA],
    fetchPolicy: "no-cache",
  });

  return { blockMerchantByNetworkIdFn, loading };
};

export function useLazyReadBlockedMerchantsByMerchantIdFromAccount() {
  const [
    blockedMerchantsByMerchantIdFromAccount,
    setBlockedMerchantsByMerchantIdFromAccount,
  ] = useState<{ merchantName: string; networkId: string }[]>([]);

  const [blockedMerchantsByMerchantIdFromAccountFn, { loading }] =
    useLazyQuery<{
      readBlockedMerchantsByMerchantIdFromAccount: {
        blockedMerchantsNetworkIds: {
          merchantName: string;
          networkId: string;
        }[];
      };
      code: string;
      message: string;
    }>(READ_BLOCKED_MERCHANTS_BY_MERCHANT_ID_FROM_ACCOUNT, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (
          data?.readBlockedMerchantsByMerchantIdFromAccount
            ?.blockedMerchantsNetworkIds?.length > 0
        ) {
          setBlockedMerchantsByMerchantIdFromAccount(
            data?.readBlockedMerchantsByMerchantIdFromAccount
              ?.blockedMerchantsNetworkIds,
          );
        }
      },
      notifyOnNetworkStatusChange: true,
    });

  return {
    blockedMerchantsByMerchantIdFromAccountFn,
    blockedMerchantsByMerchantIdFromAccount,
    loading,
  };
}

export const useUpdateTransactionMemo = ({
  onSubmit,
  initialValues,
}: useUpdateTransactionMemoProps) => {
  const [updateTransactionMemo] = useMutation(UPDATE_TRANSACTION_MEMO);

  const {
    handleChange: handleUpdateTransactionMemoChange,
    handleSubmit: handleUpdateTransactionMemoSubmit,
    values: updateTransactionMemoValues,
    isSubmitting: updateTransactionMemoSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        const data = { ...values };
        const res = await updateTransactionMemo({
          variables: { data },
        });
        onSubmit(res, null);
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });

  return {
    updateTransactionMemoValues,
    handleUpdateTransactionMemoChange,
    handleUpdateTransactionMemoSubmit,
    updateTransactionMemoSubmitting,
    setFieldValue,
  };
};
