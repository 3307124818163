/** @format */

import { useMutation } from "@apollo/client";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { ENUM_REPAYMENT_DURATION_TYPE } from "@prisma/client";
import {
  CREATE_REPAYMENT_PLAN,
  GET_REPAYMENT_PLAN_DETAILS,
} from "@roadflex/graphql";
import { RepaymentPlanDetails } from "@roadflex/types";
import { useFormik } from "formik";
import Link from "next/link";
import { useRouter } from "next/router";
import { InputSwitch } from "primereact/inputswitch";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { Select } from "../../../dropdowns";
import { Toast } from "../../../toast-message/toast";

export type RepaymentPlanUIProps = {
  repaymentplanDetails?: RepaymentPlanDetails;
};

const durationInNumberOptions = [
  { label: "4 weeks", value: 4 },
  { label: "6 weeks", value: 6 },
  { label: "8 weeks", value: 8 },
  { label: "10 weeks", value: 10 },
  { label: "12 weeks", value: 12 },
];
export default function RepaymentPlanUI({
  repaymentplanDetails,
}: RepaymentPlanUIProps) {
  const [createRepaymentPlanFn, { loading: updatingDetails }] = useMutation(
    CREATE_REPAYMENT_PLAN,
    {
      refetchQueries: [GET_REPAYMENT_PLAN_DETAILS],
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    },
  );
  const router = useRouter();
  const backToPaymentsPanel = () => {
    router.push("/payments");
  };

  const { values, setFieldValue, handleSubmit, setFieldTouched } = useFormik({
    initialValues: {
      durationInNumber: repaymentplanDetails?.durationInNumber
        ? repaymentplanDetails.durationInNumber
        : 4,
      enabled: repaymentplanDetails ? true : false,
    },

    onSubmit: async (value) => {
      try {
        const res = await createRepaymentPlanFn({
          variables: {
            data: {
              durationInNumber: value.durationInNumber,
              repaymentDurationType: ENUM_REPAYMENT_DURATION_TYPE.WEEKLY,
            },
          },
        });
        if (res.data.createRepayment.code === "200") {
          Toast({
            type: "success",
            message: "New repayment plan added successfully",
          });
        } else {
          Toast({ type: "error", message: res.data.createRepayment.message });
        }
      } catch (err) {
        if (err instanceof Error) {
          Toast({ type: "error", message: err.message });
        }
      }
    },
  });

  return (
    <div className="flex flex-col flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4">
      <div className="flex flex-col w-full rounded-md md:my-12">
        <div className="flex flex-col justify-between gap-3 md:flex-row">
          <div className="flex flex-row items-center">
            <div className="">
              <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.GrayOutline}
                onClick={backToPaymentsPanel}
                className="pl-0 ml-[-6px] border-0"
              >
                <span className="flex items-center h-5">
                  <ChevronRightIcon
                    className={
                      "-rotate-180 h-5 w-5 transform ease-in duration-300"
                    }
                    aria-hidden="true"
                  />
                </span>
                Back
              </Button>
            </div>
            <div className="ml-4 text-sm font-medium md:text-lg">
              <span> {`REPAYMENT PLAN`.toUpperCase()}</span>
            </div>
          </div>
        </div>
      </div>
      <form
        className="flex flex-col h-full p-4 mt-5 overflow-y-auto bg-white rounded-md shadow md:p-8 text-brown-500 md:mt-0"
        onSubmit={handleSubmit}
      >
        {/* <h2 className="text-lg font-bold">Repayment Plan</h2> */}

        <div className="flex flex-col justify-between flex-grow h-full">
          <div className="space-y-4">
            <div className="flex items-center h-6">
              <span className="mr-4 font-semibold">Enable Repayment</span>
              <InputSwitch
                id="enabled"
                name="enabled"
                checked={values.enabled}
                onChange={() => setFieldValue("enabled", !values.enabled)}
                className="border-0 input-switch"
                disabled={Object.keys(repaymentplanDetails || {}).length > 0}
              />
            </div>
            <p className="text-sm text-left text-brown-500">
              Repay your outstanding amount in equal amounts weekly across{" "}
              {values.durationInNumber} weeks.
            </p>
            <div>
              <div className="mt-4 mb-1 font-semibold md:mt-8">
                Repayment Duration
              </div>
              <Select
                value={values.durationInNumber}
                name="durationInNumber"
                disabled={Object.keys(repaymentplanDetails || {}).length > 0}
                id="durationInNumber"
                options={durationInNumberOptions}
                className="child:text-sm"
                panelStyle={{ fontSize: "14px" }}
                onChange={(e) => setFieldValue("durationInNumber", e.value)}
                onBlur={() => setFieldTouched("durationInNumber", true)}
                placeholder="Select Duration"
              ></Select>
            </div>
            {repaymentplanDetails && (
              <>
                <div>
                  <span className="text-sm font-semibold">
                    {" "}
                    Total Payments made till now:{" "}
                  </span>
                  {repaymentplanDetails.paymentHistory.length}
                </div>
                <div className="text-sm font-semibold">
                  For more details check:{" "}
                  <span className="underline text-brown-700">
                    <Link href={"/payments-history"}>Payments History</Link>
                  </span>
                </div>
              </>
            )}
          </div>
          <div className="flex flex-row-reverse w-full mt-4 md:mt-8">
            <Button
              variant={ButtonVariant.AppOrange}
              size={ButtonSize.AppSize}
              type={ButtonType.Submit}
              className="flex flex-row items-center w-full sm:w-auto"
              disabled={
                updatingDetails ||
                Object.keys(repaymentplanDetails || {}).length > 0 ||
                !values.enabled
              }
              loading={updatingDetails}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
