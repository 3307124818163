import { useMutation } from "@apollo/client";
import {
  READ_STRIPE_CUSTOMER_PAYMENT_METHODS,
  STRIPE_ADD_NEW_PAYMENT_METHOD,
} from "@roadflex/graphql";
import { useState } from "react";
import { Toast } from "../../toast-message/toast";
import { StripeAddNewCardButtonUI } from "./stripe-add-new-card.ui";

type StripeAddNewCardButtonProps = {
  onRepaymentActivate?: () => void;
  onDeposit?: () => void;
  phrasing: string;
  disabled?: boolean;
  loading?: boolean;
};
export const StripeAddNewCardButton = ({
  onRepaymentActivate,
  onDeposit,
  disabled,
  loading,
  phrasing,
}: StripeAddNewCardButtonProps) => {
  const [addPaymentMethodFn, { loading: addingPaymentMethod }] = useMutation(
    STRIPE_ADD_NEW_PAYMENT_METHOD,
    {
      refetchQueries: onDeposit ? [] : [READ_STRIPE_CUSTOMER_PAYMENT_METHODS],
    },
  );

  const [clientSecret, setClientSecret] = useState("");
  const [showSetupIntentForm, setShowSetupIntentForm] = useState(false);

  const addPaymentMethod = async () => {
    try {
      const resp = await addPaymentMethodFn();
      setClientSecret(resp?.data?.stripeAddNewPaymentMethod?.clientSecret);
      setShowSetupIntentForm(true);
    } catch (err) {
      if (err instanceof Error) {
        Toast({ type: "error", message: err.message });
      } else {
        Toast({ type: "error", message: "Something went wrong" });
      }
    }
  };

  return (
    <StripeAddNewCardButtonUI
      addPaymentMethod={addPaymentMethod}
      clientSecret={clientSecret}
      addingPaymentMethod={addingPaymentMethod}
      showSetupIntentForm={showSetupIntentForm}
      setShowSetupIntentForm={setShowSetupIntentForm}
      onRepaymentActivate={onRepaymentActivate}
      onDeposit={onDeposit}
      phrasing={phrasing}
      disabled={disabled}
      loading={loading}
    ></StripeAddNewCardButtonUI>
  );
};
