/* eslint-disable max-lines */
/** @format */

import { useMutation } from "@apollo/client";
import {
  ArrowRightIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ChevronRightIcon,
  InformationCircleIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import {
  ENUM_CARD_SHARING,
  ENUM_CLASSIFICATION,
  ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_FUEL_CATEGORIES,
  ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_NON_FUEL_CATEGORIES,
  ENUM_GENERIC_ID_PROMPT_ENTITY_TYPE,
  ENUM_GENERIC_ID_PROMPT_MODE,
  ENUM_TELEMATICS_SECURITY,
} from "@prisma/client";
import {
  appPolicyValidationSchema,
  CATEGORIES_MASTER,
  COUNTRY_CODES,
  DAYS_OF_WEEK,
  ENUM_AUTHORIZATION_METHOD,
  GENERIC_ID_PROMPT_MODE,
  OPERATIONAL_HOURS_TIMES,
  STATE_CODES,
} from "@roadflex/constants";
import { CREATE_UPDATE_POLICY, READ_CARD_POLICY } from "@roadflex/graphql";
import {
  CardMerchants,
  CardRuleDetails,
  CardRuleType,
  ChildFeatureType,
  ExtendedCardRuleDetailsType,
  GlobalSettingType,
  OperationalHours,
  PolicyRestrictionFormikValues,
  TabsType,
} from "@roadflex/types";
import { humanize, humanizeEnum } from "@roadflex/utils";
import classNames from "classnames";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useState } from "react";
import { Toast } from "../../../../src/toast-message/toast";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { CustomMultiSelect, Select } from "../../../dropdowns";
import { CheckboxInput, TextInput } from "../../../inputs";
import { InfoModal } from "../../../modals";
import { FuelCardComponentPermissionGuard } from "../../../permission-guard";
import { DynamicPurchaseControls } from "../../dynamic-purchase-controls/dynamic-purchase-controls";
import PolicyMerchantDisclosureUI from "./policy-merchant-disclosure.ui";

interface PolicyUIProps {
  tabs: TabsType[];
  cardRuleData: CardRuleType | null;
  adminCardSettingData: GlobalSettingType;
  submittingRequest?: boolean;
  setsubmittingRequest: (val: boolean) => void;
  editLimits: boolean;
  setEditLimits: (value: boolean) => void;
  editCardRules: boolean;
  setEditCardRules: (value: boolean) => void;
  merchantOriginal?: CardMerchants[];
  childFeatures: ChildFeatureType[];
  cardSharingControls: ENUM_CARD_SHARING;
}

// eslint-disable-next-line max-lines-per-function
export const PolicyUI = ({
  tabs,
  cardRuleData,
  adminCardSettingData,
  submittingRequest,
  editLimits,
  setEditLimits,
  editCardRules,
  setEditCardRules,
  setsubmittingRequest,
  merchantOriginal,
  childFeatures,
  cardSharingControls,
}: PolicyUIProps) => {
  const router = useRouter();
  const minDate = new Date();
  minDate.setMonth(0); //means January, because 0-indexed
  minDate.setFullYear(2022);
  minDate.setDate(1);

  const [tabOpen, setTabOpen] = useState(1);
  const [createUpdateCardRuleRequest] = useMutation<{
    createUpdateCardRule: {
      message: string;
      code: string;
    };
  }>(CREATE_UPDATE_POLICY, {
    refetchQueries: [READ_CARD_POLICY],
  });
  const operationalHours = cardRuleData?.operationalHours || [];
  const [fuelExpensesCategories, setFuelExpensesCategories] = useState<
    CardMerchants[]
  >([]);
  const [businessExpensesCategories, setBusinessExpensesCategories] = useState<
    CardMerchants[]
  >([]);
  const [vehicleExpensesCategories, setVehicleExpensesCategories] = useState<
    CardMerchants[]
  >([]);
  const [otherCategories, setOtherCategories] = useState<CardMerchants[]>([]);
  const [otherMerchantCategories, setOtherMerchantCategories] = useState<
    CardMerchants[]
  >([]);
  const [createMode, setCreateMode] = useState<boolean>(false);

  const initializeEditedOperationalHours = (
    opHours: OperationalHours[],
  ): OperationalHours[] => {
    return DAYS_OF_WEEK.map((day) => {
      const existingOpHours = opHours.find((oh) => oh.dayOfWeek === day);
      if (existingOpHours) {
        existingOpHours.isEnabled = true;
        return existingOpHours;
      }
      return {
        isEnabled: false,
        dayOfWeek: day,
        startTime: "",
        endTime: "",
      };
    });
  };

  const [tooltip, settooltip] = useState(false);
  const [editedOperationalHours, setEditedOperationalHours] = useState<
    OperationalHours[]
  >(initializeEditedOperationalHours(operationalHours));

  const access = {
    CardRulesAccess:
      childFeatures.find(
        (item) => item.name === "SPEND_POLICY_PAGE_DETAILS_CARD_RULES",
      ) !== undefined,
    OperationalHoursAccess:
      childFeatures
        .find((item) => item.name === "SPEND_POLICY_PAGE_DETAILS_CARD_RULES")
        ?.grandchildren?.find(
          (item) =>
            item.name ===
            "SPEND_POLICY_PAGE_DETAILS_CARD_RULES_OPERATIONAL_HOURS",
        ) !== undefined,
    RestrictPurchaseAccess:
      childFeatures
        .find((item) => item.name === "SPEND_POLICY_PAGE_DETAILS_CARD_RULES")
        ?.grandchildren?.find(
          (item) =>
            item.name ===
            "SPEND_POLICY_PAGE_DETAILS_CARD_RULES_RESTRICT_PURCHASE",
        ) !== undefined,
    TransactionControlsAccess:
      childFeatures
        .find((item) => item.name === "SPEND_POLICY_PAGE_DETAILS_CARD_RULES")
        ?.grandchildren?.find(
          (item) =>
            item.name ===
            "SPEND_POLICY_PAGE_DETAILS_CARD_RULES_TRANSACTION_CONTROLS",
        ) !== undefined,
    SpendLimitsAccess:
      childFeatures.find(
        (item) => item.name === "SPEND_POLICY_PAGE_DETAILS_SPEND_LIMITS",
      ) !== undefined,
    LimitsAccess:
      childFeatures
        .find((item) => item.name === "SPEND_POLICY_PAGE_DETAILS_SPEND_LIMITS")
        ?.grandchildren?.find(
          (item) =>
            item.name === "SPEND_POLICY_PAGE_DETAILS_SPEND_LIMITS_SPEND_LIMIT",
        ) !== undefined,
    CategoryRestrictionsAccess:
      childFeatures
        .find((item) => item.name === "SPEND_POLICY_PAGE_DETAILS_SPEND_LIMITS")
        ?.grandchildren?.find(
          (item) =>
            item.name ===
            "SPEND_POLICY_PAGE_DETAILS_SPEND_LIMITS_CATEGORY_RESTRICTIONS",
        ) !== undefined,
    DynamicPurchaseControl:
      childFeatures.find(
        (item) =>
          item.name === "SPEND_POLICY_PAGE_DETAILS_DYNAMIC_PURCHASE_CONTROLS",
      ) !== undefined,
  };

  const {
    handleChange,
    handleBlur,
    values,
    touched,
    setFieldValue,
    errors,
    resetForm,
    validateForm,
    ...rest
  } = useFormik<PolicyRestrictionFormikValues>({
    initialValues: {
      id: cardRuleData?.id || "",
      name: cardRuleData?.name || "",
      description: cardRuleData?.description || "",
      smsSecurity: cardRuleData?.smsSecurity || false,
      dailyLimit:
        (cardRuleData?.cardSpending.find((data) => data.interval === "daily")
          ?.amount || 0) / 100 ||
        adminCardSettingData?.dailyLimit / 100 ||
        "",
      weeklyLimit:
        (cardRuleData?.cardSpending.find((data) => data.interval === "weekly")
          ?.amount || 0) / 100 || "",
      monthlyLimit:
        (cardRuleData?.cardSpending.find((data) => data.interval === "monthly")
          ?.amount || 0) / 100 || "",
      transactionLimit:
        (cardRuleData?.cardSpending.find(
          (data) => data.interval === "per_authorization",
        )?.amount || 0) / 100 ||
        adminCardSettingData?.transactionLimit / 100 ||
        "",
      enableOperationalHours: cardRuleData?.enableOperationalHours || false,
      merchants: merchantOriginal,
      enableSmsReceiptUpload: cardRuleData?.enableSmsReceiptUpload || false,
      operationalHours: cardRuleData?.operationalHours,
      fuelTypeSecurity: cardRuleData?.fuelTypeSecurity || false,
      tankCapacitySecurity: cardRuleData?.tankCapacitySecurity || false,
      telematicsSecurity:
        cardRuleData?.telematicsSecurity || ENUM_TELEMATICS_SECURITY.OFF,
      odometerSecurity: cardRuleData?.odometerSecurity || false,
      suspiciousFuelingSecurity:
        cardRuleData?.suspiciousFuelingSecurity || false,
      requireJobID: cardRuleData?.requireJobID || false,
      flaggedMerchantCategories: cardRuleData?.flaggedMerchantCategories || [],
      authorizationMethod:
        cardRuleData?.authorizationMethod ||
        Object.keys(ENUM_AUTHORIZATION_METHOD),
      blockState: cardRuleData?.blockState,
      countries: cardRuleData?.countries,
      genericIdPromptEntityType:
        cardRuleData?.genericIdPromptEntityType ||
        ENUM_GENERIC_ID_PROMPT_ENTITY_TYPE.DRIVER,
      genericIdPromptMode:
        cardRuleData?.genericIdPromptMode || ENUM_GENERIC_ID_PROMPT_MODE.NONE,
    },
    validationSchema: appPolicyValidationSchema(
      adminCardSettingData,
      cardSharingControls,
    ),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: async (value) => {},
  });

  useEffect(() => {
    if (!cardRuleData) {
      setCreateMode(true);
      setEditLimits(true);
      setEditCardRules(true);
      setFieldValue("merchants", merchantOriginal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOriginal?.length]);

  useEffect(() => {
    const fuelExpenses: CardMerchants[] = [];
    const businessExpenses: CardMerchants[] = [];
    const vehicleExpenses: CardMerchants[] = [];
    const other: CardMerchants[] = [];
    const otherOption: CardMerchants[] = [];

    if (cardRuleData?.merchants) {
      cardRuleData.merchants.forEach((merchant) => {
        if (
          merchant.classification === ENUM_CLASSIFICATION.FUEL &&
          merchant.authorized
        ) {
          fuelExpenses.push(merchant);
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.BUSINESS &&
          merchant.authorized
        ) {
          businessExpenses.push(merchant);
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.VEHICLE &&
          merchant.authorized
        ) {
          if (merchant.name !== "auto_service_shops") {
            vehicleExpenses.push(merchant);
          }
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.OTHER &&
          merchant.authorized &&
          merchant.name !== "others"
        ) {
          other.push(merchant);
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.OTHER &&
          merchant.authorized &&
          merchant.name === "others"
        ) {
          otherOption.push(merchant);
        }
      });
    } else {
      (merchantOriginal || []).forEach((merchant) => {
        if (
          merchant.classification === ENUM_CLASSIFICATION.FUEL &&
          merchant.authorized
        ) {
          fuelExpenses.push(merchant);
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.BUSINESS &&
          merchant.authorized
        ) {
          businessExpenses.push(merchant);
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.VEHICLE &&
          merchant.authorized
        ) {
          if (merchant.name !== "auto_service_shops") {
            vehicleExpenses.push(merchant);
          }
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.OTHER &&
          merchant.authorized &&
          merchant.name !== "others"
        ) {
          other.push(merchant);
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.OTHER &&
          merchant.authorized &&
          merchant.name === "others"
        ) {
          otherOption.push(merchant);
        }
      });
    }
    fuelExpenses.sort((a, b) => a.name.localeCompare(b.name));
    businessExpenses.sort((a, b) => a.name.localeCompare(b.name));
    vehicleExpenses.sort((a, b) => a.name.localeCompare(b.name));
    other.sort((a, b) => a.name.localeCompare(b.name));
    setFuelExpensesCategories(fuelExpenses);
    setBusinessExpensesCategories(businessExpenses);
    setVehicleExpensesCategories(vehicleExpenses);
    setOtherCategories(other);
    setOtherMerchantCategories(otherOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(values.merchants || []).length]);

  const backToPolicyPanel = () => {
    router.push("/policies");
  };

  const handleCardRuleCreateUpdate = (
    cardRuleDetails: CardRuleDetails | ExtendedCardRuleDetailsType,
  ) => {
    setsubmittingRequest(true);
    const {
      id,
      name,
      description,
      operationalHours: selectedHours,
      blockState,
      countries,
      merchants,
      monthly,
      weekly,
      daily,
      transaction,
      enableOperationalHours,
      smsSecurity,
      fuelTypeSecurity,
      tankCapacitySecurity,
      telematicsSecurity,
      odometerSecurity,
      requireJobID,
      suspiciousFuelingSecurity,
      enableSmsReceiptUpload,
      flaggedMerchantCategories,
      authorizationMethod,
      genericIdPromptEntityType,
      genericIdPromptMode,
    } = cardRuleDetails;
    const newMerchants: CardMerchants[] = [];
    if (merchants) {
      for (let i = 0; i < merchants.length; i++) {
        const newObj = merchants[i];
        Object.keys(newObj).forEach((key) => {
          if (
            newObj[key as keyof typeof newObj] === null ||
            key === "__typename"
          ) {
            delete newObj[key as keyof typeof newObj];
          }
        });
        newMerchants.push(newObj);
      }
    }

    createUpdateCardRuleRequest({
      variables: {
        data: {
          id: id,
          name: name,
          description: description,
          operationalHours: selectedHours,
          enableOperationalHours: enableOperationalHours,
          blockState: blockState,
          countries: countries,
          merchants: newMerchants,
          monthly: monthly,
          weekly: weekly,
          daily: daily,
          transaction: transaction,
          smsSecurity: smsSecurity,
          fuelTypeSecurity,
          tankCapacitySecurity,
          telematicsSecurity,
          odometerSecurity,
          requireJobID,
          suspiciousFuelingSecurity,
          enableSmsReceiptUpload,
          flaggedMerchantCategories,
          authorizationMethod,
          genericIdPromptEntityType,
          genericIdPromptMode,
          dynamicPurchaseControlsSetting:
            "dynamicPurchaseControlsSetting" in cardRuleDetails
              ? cardRuleDetails.dynamicPurchaseControlsSetting
              : undefined,
          dynamicPurchaseControlsAllowedFuelCategories:
            "dynamicPurchaseControlsAllowedFuelCategories" in cardRuleDetails
              ? cardRuleDetails.dynamicPurchaseControlsAllowedFuelCategories
              : undefined,
          dynamicPurchaseControlsAllowedNonFuelCategories:
            "dynamicPurchaseControlsAllowedNonFuelCategories" in cardRuleDetails
              ? cardRuleDetails.dynamicPurchaseControlsAllowedNonFuelCategories
              : undefined,
        },
      },
    })
      .then(({ data }) => {
        if (data?.createUpdateCardRule?.code === "200") {
          Toast({
            type: "success",
            message: data?.createUpdateCardRule?.message,
          });
          if (createMode) {
            backToPolicyPanel();
          }
        } else {
          Toast({
            type: "error",
            message: data?.createUpdateCardRule?.message || "",
          });
        }
        setsubmittingRequest(false);
        setEditCardRules(false);
        setEditLimits(false);
      })
      .catch((err) => {
        setsubmittingRequest(false);
        Toast({
          type: "error",
          message: err.message,
        });
      });
  };

  // New function to validate and submit
  const validateAndSubmit = async ({
    createNew,
    additionalValues,
  }: {
    createNew: boolean;
    additionalValues?: {
      dynamicPurchaseControlsSetting: boolean;
      dynamicPurchaseControlsAllowedFuelCategories: ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_FUEL_CATEGORIES[];
      dynamicPurchaseControlsAllowedNonFuelCategories: ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_NON_FUEL_CATEGORIES[];
    };
  }) => {
    const validationErrors = await validateForm();

    if (Object.keys(validationErrors).length > 0) {
      const firstErrorMessage = Object.values(validationErrors)[0];
      Toast({
        type: "error",
        message: firstErrorMessage as string,
      });
      return;
    }
    // Proceed with submission logic if no validation errors
    const operationalHoursList = editedOperationalHours
      .filter((oh) => oh.isEnabled && oh.startTime && oh.endTime)
      .map((filteredOh) => ({
        dayOfWeek: filteredOh.dayOfWeek,
        startTime: filteredOh.startTime,
        endTime: filteredOh.endTime,
      }));

    const enableOpHours: boolean =
      operationalHoursList.length > 0 && values.enableOperationalHours;

    const data: CardRuleDetails | ExtendedCardRuleDetailsType = {
      id: values.id,
      name: values.name,
      description: values.description,
      smsSecurity: values.smsSecurity,
      fuelTypeSecurity: values.fuelTypeSecurity,
      tankCapacitySecurity: values.tankCapacitySecurity,
      telematicsSecurity: values.telematicsSecurity,
      odometerSecurity: values.odometerSecurity,
      requireJobID: values.requireJobID,
      suspiciousFuelingSecurity: values.suspiciousFuelingSecurity,
      monthly: values.monthlyLimit ? Number(values.monthlyLimit) * 100 : 0,
      weekly: values.weeklyLimit ? Number(values.weeklyLimit) * 100 : 0,
      daily: values.dailyLimit ? Number(values.dailyLimit) * 100 : 0,
      transaction: values.transactionLimit
        ? Number(values.transactionLimit) * 100
        : 0,
      operationalHours: operationalHoursList,
      enableOperationalHours: enableOpHours,
      blockState: values?.blockState,
      countries: values?.countries,
      merchants: values.merchants,
      enableSmsReceiptUpload: values.enableSmsReceiptUpload,
      flaggedMerchantCategories: values.flaggedMerchantCategories,
      authorizationMethod: values.authorizationMethod,
      genericIdPromptEntityType:
        values.genericIdPromptEntityType ||
        ENUM_GENERIC_ID_PROMPT_ENTITY_TYPE.DRIVER,
      genericIdPromptMode:
        values.genericIdPromptMode || ENUM_GENERIC_ID_PROMPT_MODE.NONE,
      ...additionalValues,
    };

    if (handleCardRuleCreateUpdate) {
      handleCardRuleCreateUpdate(data);
    }
  };

  const times = OPERATIONAL_HOURS_TIMES;

  const handleCheckboxChange = (dayOfWeek: string, checked: boolean) => {
    setEditedOperationalHours((prev) =>
      prev.map((oh) =>
        oh.dayOfWeek === dayOfWeek
          ? {
              ...oh,
              startTime: checked ? oh.startTime : "",
              endTime: checked ? oh.endTime : "",
              isEnabled: checked,
            }
          : oh,
      ),
    );
  };

  const handleMerchantChange = <K extends keyof CardMerchants>(
    merchantType: CardMerchants,
    value: CardMerchants[K],
    updateField: K,
  ) => {
    const newObject = values.merchants;

    if (newObject) {
      let index = newObject?.findIndex((val) => val.name === merchantType.name);
      newObject[index][updateField] = value;

      if (merchantType.name === "auto_body_repair_shops") {
        index = newObject?.findIndex(
          (val) => val.name === "auto_service_shops",
        );
        newObject[index][updateField] = value;
      }
      setFieldValue("merchants", newObject);
    }
  };

  const [overallToggle, setOverallToggle] = useState(false);

  const handleOverallToggle = (checked: boolean) => {
    setOverallToggle(checked);
    const updatedMerchants = (values.merchants || []).map((merchant) => ({
      ...merchant,
      toggleOn: checked,
    }));
    setFieldValue("merchants", updatedMerchants);
  };

  const handleCancel = () => {
    setEditedOperationalHours(
      initializeEditedOperationalHours(operationalHours),
    );
    resetForm();
    setFieldValue(
      "merchants",
      JSON.parse(JSON.stringify(cardRuleData?.merchants)),
    );
    setEditCardRules(false);
    setEditLimits(false);
    setOverallToggle(false);
  };

  return (
    <div className="flex-col flex flex-1 pb-28 mt-0 m-4 h-full md:min-h-[calc(100vh-96px)] md:pb-4 md:px-14">
      <div className="flex flex-col w-full rounded-md md:my-12">
        <div className="flex flex-col justify-between md:flex-row">
          <div className="flex flex-row items-center">
            <div className="">
              <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.GrayOutline}
                onClick={backToPolicyPanel}
                className="!pl-0 ml-[-6px] border-0"
              >
                <span className="flex items-center h-5">
                  <ChevronRightIcon
                    className={
                      "-rotate-180 h-5 w-5 transform ease-in duration-300"
                    }
                    aria-hidden="true"
                  />
                </span>
                Back
              </Button>
            </div>
            <div className="ml-4 text-sm font-medium md:text-lg">
              {cardRuleData?.name
                ? `Spend Policy - ${values.name}`
                : "New Spend Policy"}
            </div>
          </div>
        </div>
      </div>
      <form className="w-full mt-5 md:mt-0">
        <nav className="flex -mb-px space-x-8 overflow-auto">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tabOpen === tab.id
                  ? "border-black text-black"
                  : "border-transparent hover:border-black",
                "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
              )}
              onClick={(e) => {
                e.preventDefault();
                setTabOpen(tab.id);
              }}
            >
              {tab.name === "Dynamic Purchase Controls" ? (
                <>
                  {tab.name}{" "}
                  <span className="text-yellow-600">(Enterprise)</span>
                </>
              ) : (
                <>{tab.name}</>
              )}
            </a>
          ))}
        </nav>
        <div className="border-b border-brown-500"></div>

        {access.CardRulesAccess && (
          <div className={`${tabOpen === 1 ? `block` : `hidden`} mt-4 md:mt-6`}>
            <div className="py-4 text-sm bg-white rounded-md shadow md:py-8 md:text-base">
              <div className="flex flex-col gap-4 overflow-hidden md:gap-8">
                <div className="px-4 font-medium border-gray-300 ">
                  <div className="font-semibold text-brown-500 md:text-lg">
                    Policy Details
                  </div>
                </div>
                <div className="h-0.5 border-0 bg-brown-500"></div>
                <div className="flex flex-row items-center w-full px-4">
                  <div className="w-full">
                    <div className="flex flex-row items-center justify-between border-gray-300">
                      <div className="">Name</div>
                      <div className="md:w-1/4">
                        <TextInput
                          name="name"
                          id="name"
                          value={values.name}
                          disabled={!createMode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Policy Name"
                          className=""
                          touched={touched.name}
                          error={touched.name && Boolean(errors.name)}
                          errorMessage={errors.name}
                        ></TextInput>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
              <div className="flex flex-col gap-4 overflow-hidden md:gap-8">
                <div className="px-4 font-medium border-gray-300 ">
                  <div className="font-semibold text-brown-500 md:text-lg">
                    Operational Hours{" "}
                    <span className="font-normal text-yellow-600">
                      (Premium)
                    </span>
                  </div>
                </div>
                <div className="h-0.5 border-0 bg-brown-500"></div>
                <div className="flex flex-row items-center w-full px-4 ">
                  <div className="w-full">
                    <div className="flex flex-row items-center justify-between w-full mb-4">
                      <div className="text-left">
                        Limit spending by days and times in PST?{" "}
                        <span className="font-normal text-yellow-600">
                          (Premium)
                        </span>
                      </div>
                      <div className="ml-4 text-left">
                        <FuelCardComponentPermissionGuard
                          type="inputswitch"
                          featurePermission={access.OperationalHoursAccess}
                        >
                          <InputSwitch
                            name="enableOperationalHours"
                            checked={values.enableOperationalHours}
                            onChange={handleChange}
                            disabled={!editCardRules}
                            className="border-0 input-switch"
                          />
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-col divide-y divide-gray-300">
                      {editedOperationalHours.map((dayOperationalHours) => {
                        const disableEdit = !(
                          dayOperationalHours.isEnabled &&
                          values.enableOperationalHours &&
                          editCardRules
                        );
                        return (
                          <div
                            key={dayOperationalHours.dayOfWeek}
                            className={classNames(
                              dayOperationalHours.isEnabled
                                ? "text-black"
                                : "text-gray-500",
                              "flex flex-col gap-3 sm:flex-row md:flex-col lg:flex-row sm:items-center md:items-start lg:items-center justify-between py-4 first:pt-0 last:pb-0 border-gray-300",
                            )}
                          >
                            <div className="flex flex-row items-center">
                              <CheckboxInput
                                label={dayOperationalHours.dayOfWeek.toLowerCase()}
                                labelClass="capitalize"
                                id={`isEnabled-${dayOperationalHours.dayOfWeek}`}
                                type="checkbox"
                                name={`isEnabled-${dayOperationalHours.dayOfWeek}`}
                                checked={dayOperationalHours.isEnabled}
                                disabled={
                                  !values.enableOperationalHours ||
                                  !editCardRules
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    dayOperationalHours.dayOfWeek,
                                    e.target.checked,
                                  )
                                }
                              ></CheckboxInput>
                            </div>
                            <div className="flex flex-row items-center">
                              <Select
                                id="start-time"
                                value={dayOperationalHours.startTime}
                                disabled={disableEdit}
                                onChange={(e) =>
                                  setEditedOperationalHours((prev) =>
                                    prev.map((oh) =>
                                      oh.dayOfWeek ===
                                      dayOperationalHours.dayOfWeek
                                        ? {
                                            ...oh,
                                            startTime: e.target.value,
                                          }
                                        : oh,
                                    ),
                                  )
                                }
                                placeholder="-- Select --"
                                className="w-[120px]"
                                optionLabel="label"
                                optionValue="value"
                                options={[
                                  {
                                    label: "-- Select --",
                                    value: "",
                                  },
                                ].concat(
                                  times.map((time) => {
                                    return { label: time, value: time };
                                  }),
                                )}
                              ></Select>
                              <label className="m-4">to</label>
                              <Select
                                id="end-time"
                                value={dayOperationalHours.endTime}
                                disabled={disableEdit}
                                onChange={(e) =>
                                  setEditedOperationalHours((prev) =>
                                    prev.map((oh) =>
                                      oh.dayOfWeek ===
                                      dayOperationalHours.dayOfWeek
                                        ? {
                                            ...oh,
                                            endTime: e.target.value,
                                          }
                                        : oh,
                                    ),
                                  )
                                }
                                placeholder="-- Select --"
                                className="w-[120px]"
                                optionLabel="label"
                                optionValue="value"
                                options={[
                                  {
                                    label: "-- Select --",
                                    value: "",
                                  },
                                ].concat(
                                  times
                                    .slice(
                                      times.findIndex(
                                        (time) =>
                                          time ===
                                          dayOperationalHours.startTime,
                                      ),
                                    )
                                    .map((time) => {
                                      return { label: time, value: time };
                                    }),
                                )}
                              ></Select>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
              <div className="flex flex-col gap-4 overflow-hidden md:gap-8 ">
                <div className="px-4 font-medium">
                  <div className="font-semibold text-brown-500 md:text-lg">
                    Restrict Purchase States{" "}
                    <span className="font-normal text-yellow-600">
                      (Enterprise)
                    </span>
                  </div>
                </div>
                <div className="h-0.5 border-0 bg-brown-500"></div>
                <div className="flex flex-col items-center w-full gap-4 px-4 divide-y divide-gray-300">
                  <div className="w-full">
                    <div className="mb-2 text-left">
                      Block purchases from these US states
                    </div>
                    <div className="flex flex-row items-center justify-between w-full mb-4">
                      <FuelCardComponentPermissionGuard
                        type="checkbox"
                        featurePermission={access.RestrictPurchaseAccess}
                        messageType="enterprise"
                      >
                        <CustomMultiSelect
                          name="state"
                          id="state"
                          className="border-none"
                          disabled={!editCardRules}
                          value={
                            values.blockState
                              ? values.blockState.split(",")
                              : []
                          }
                          onChange={(e) => {
                            setFieldValue(
                              "blockState",
                              e.target.value.join(","),
                            );
                          }}
                          options={STATE_CODES.concat({
                            label: "Washington, D.C.",
                            value: "DC",
                          }).map((m) => {
                            return {
                              label: `${m.label} (${m.value})`,
                              value: m.value,
                              key: m.value,
                              disabled: false,
                            };
                          })}
                        ></CustomMultiSelect>
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                  <div className="w-full py-4">
                    <div className="mb-2 text-left">Country</div>
                    <div className="flex flex-row items-center w-full">
                      <FuelCardComponentPermissionGuard
                        type="checkbox"
                        featurePermission={access.RestrictPurchaseAccess}
                        messageType="enterprise"
                      >
                        <CustomMultiSelect
                          name="countries"
                          id="countries"
                          className="border-none"
                          disabled={!editCardRules}
                          value={
                            values.countries
                              ? values.countries.split(",")
                              : ["US"]
                          }
                          onChange={(e) => {
                            setFieldValue(
                              "countries",
                              e.target.value.join(","),
                            );
                          }}
                          options={COUNTRY_CODES.map((m) => {
                            return {
                              label: `${m.label} (${m.value})`,
                              value: m.value,
                              key: m.value,
                              disabled: false,
                            };
                          })}
                        ></CustomMultiSelect>
                        {values?.countries?.includes("CA") && (
                          <div className="mt-2 ml-2 text-sm text-orange-500">
                            Enabling this option has no effect on your account
                            unless RoadFlex enables this on the backend
                          </div>
                        )}
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
              <div className="flex flex-col gap-4 overflow-hidden md:gap-8 ">
                <div className="px-4 font-medium">
                  <div className="font-semibold text-brown-500 md:text-lg">
                    Allowed Authorization Types
                    <span className="font-normal text-yellow-600"></span>
                  </div>
                </div>
                <div className="h-0.5 border-0 bg-brown-500"></div>
                <div className="flex flex-row items-center w-full px-4">
                  <div className="w-full">
                    <div className="mb-2 text-left">Authorization Types</div>
                    <div className="flex flex-row items-center justify-between w-full mb-4">
                      <FuelCardComponentPermissionGuard
                        type="checkbox"
                        featurePermission={access.TransactionControlsAccess}
                        messageType="premium"
                      >
                        <CustomMultiSelect
                          value={values.authorizationMethod}
                          options={[
                            {
                              label: "-- Select multiple options --",
                              value: "",
                              disabled: true,
                            },
                          ].concat(
                            Object.keys(ENUM_AUTHORIZATION_METHOD).map(
                              (categories) => {
                                const label =
                                  ENUM_AUTHORIZATION_METHOD[categories];
                                return {
                                  label: humanize(label),
                                  value: categories,
                                  disabled: false,
                                };
                              },
                            ),
                          )}
                          onChange={(e) =>
                            setFieldValue("authorizationMethod", e.value)
                          }
                          placeholder="-- Select multiple options --"
                          optionLabel="label"
                          optionValue="value"
                          maxSelectedLabels={1}
                          disabled={!editCardRules}
                        ></CustomMultiSelect>
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
              <div className="flex flex-col gap-4 overflow-hidden md:gap-8">
                {" "}
                <div className="px-4 font-semibold text-brown-500 md:text-lg ">
                  Generic Id Prompt Configuration{" "}
                  <span className="font-normal text-yellow-600">
                    (Enterprise)
                  </span>
                </div>
                <div className="h-0.5 border-0 bg-brown-500"></div>
                <div className="flex flex-col gap-4 px-4 ">
                  <div className="divide-y divide-gray-300">
                    <div className="flex flex-row items-center justify-between gap-4 pb-4">
                      <div className="flex items-center">
                        Generic Id Prompt Mode{" "}
                        <span className="content-start self-start inline-block my-auto ml-2">
                          <Tooltip
                            target={`.generic-id-prompt-mode`}
                            position="top"
                            className="max-w-sm text-sm border-black"
                            // content={}
                          >
                            {" "}
                            <>
                              <div>
                                <span className="font-bold">
                                  Verification Only Mode:
                                </span>{" "}
                                This mode verifies that the selected entity type
                                matches the one currently assigned to the card.
                                If there is no entity assigned, or if the
                                assigned entity does not match, the transaction
                                will be declined.
                              </div>
                              <div>
                                <span className="font-bold">
                                  Verification and Assignment Mode:
                                </span>{" "}
                                This mode checks whether the selected entity
                                type exists in your account. If it does, it will
                                assign the entity to the transaction; if it does
                                not, the transaction will be declined.
                              </div>
                            </>
                          </Tooltip>
                          <InformationCircleIcon
                            className={`w-5 h-5 text-brown-700 generic-id-prompt-mode`}
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <Select
                        id="genericIdPromptMode"
                        value={values.genericIdPromptMode}
                        options={GENERIC_ID_PROMPT_MODE}
                        onChange={(e) => {
                          setFieldValue("genericIdPromptMode", e.value);
                          setFieldValue(
                            "genericIdPromptEntityType",
                            ENUM_GENERIC_ID_PROMPT_ENTITY_TYPE.DRIVER,
                          );
                        }}
                        placeholder="No Generic Id Mode Selected"
                        className="min-w-[150px] max-w-[300px]"
                        disabled={!editCardRules}
                      ></Select>
                    </div>
                    <div
                      className={`flex flex-row items-center justify-between gap-4 pt-4`}
                    >
                      <div
                        className={`${
                          values.genericIdPromptMode ===
                          ENUM_GENERIC_ID_PROMPT_MODE.NONE
                            ? "opacity-50"
                            : ""
                        } flex items-center`}
                      >
                        Generic Id Prompt Entity Type
                        <span className="content-start self-start inline-block my-auto ml-2">
                          <Tooltip
                            target={`.generic-id-entity-type`}
                            position="top"
                            className="max-w-sm text-sm border-black"
                            // content={}
                          >
                            {" "}
                            <>
                              <div>
                                <span className="font-bold">Driver:</span> In{" "}
                                <span className="font-semibold">
                                  Verification Only Mode
                                </span>
                                , the assigned driver's prompt ID must match the
                                ID entered. In{" "}
                                <span className="font-semibold">
                                  Verification & Assignment Mode
                                </span>
                                , if the driver exists in the account, they're
                                verified and assigned.
                              </div>
                              <div>
                                <span className="font-bold">Vehicle:</span> The
                                assigned vehicle's prompt ID is compared against
                                the ID entered at the POS terminal to verify a
                                match.
                              </div>
                              <div>
                                <span className="font-bold">Driver Tag:</span>{" "}
                                The driver tag's prompt ID is compared against
                                the ID entered at the POS terminal. If the
                                driver tag is linked to the assigned driver, the
                                transaction is approved; otherwise, it is
                                declined.
                              </div>
                              <div>
                                <span className="font-bold">Vehicle Tag:</span>{" "}
                                The vehicle tag's prompt ID is compared against
                                the ID entered at the POS terminal. If the
                                vehicle tag is linked to the assigned vehicle,
                                the transaction is approved; otherwise, it is
                                declined.
                              </div>
                              <div>
                                <span className="font-bold">Card Tag:</span> The
                                card tag's prompt ID is compared against the ID
                                entered at the POS terminal. If the card tag is
                                linked to the card, the transaction is approved;
                                otherwise, it is declined.
                              </div>
                            </>
                          </Tooltip>
                          <InformationCircleIcon
                            className={`w-5 h-5 text-brown-700 generic-id-entity-type`}
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <Select
                        id="genericIdPromptEntityType"
                        value={values.genericIdPromptEntityType}
                        options={Object.keys(ENUM_GENERIC_ID_PROMPT_ENTITY_TYPE)
                          ?.filter((entity) =>
                            values.genericIdPromptMode ===
                            ENUM_GENERIC_ID_PROMPT_MODE.VERIFY_AND_ASSIGN
                              ? entity ===
                                ENUM_GENERIC_ID_PROMPT_ENTITY_TYPE.DRIVER
                              : true,
                          )
                          .map((entity) => {
                            return {
                              label: humanizeEnum(entity),
                              value: entity,
                              disabled: false,
                            };
                          })}
                        onChange={(e) => {
                          setFieldValue("genericIdPromptEntityType", e.value);
                        }}
                        placeholder="No Entity Selected"
                        className="min-w-[150px] max-w-[300px]"
                        disabled={
                          !editCardRules ||
                          values.genericIdPromptMode ===
                            ENUM_GENERIC_ID_PROMPT_MODE.NONE
                        }
                      ></Select>
                    </div>
                  </div>
                  {values.genericIdPromptMode ===
                    ENUM_GENERIC_ID_PROMPT_MODE.VERIFY_AND_ASSIGN && (
                    <div>
                      During the verification and assignment process, if an
                      entity is already linked to the card, the entity provided
                      by the prompt ID takes precedence and will be assigned to
                      the transaction.
                    </div>
                  )}
                </div>
              </div>
            </div>
            {cardSharingControls === ENUM_CARD_SHARING.VEHICLE_SHARING && (
              <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
                <div className="flex flex-col gap-4 overflow-hidden md:gap-8">
                  {" "}
                  <div className="px-4 font-semibold text-brown-500 md:text-lg ">
                    Vehicle Sharing
                  </div>
                  <div className="h-0.5 border-0 bg-brown-500"></div>
                  <div className="flex flex-col px-4 ">
                    <div className="text-sm md:text-base">
                      <div className="mb-3">
                        <div className="mb-5">
                          Once enabled, only the assigned vehicle will be able
                          to use this card. Before using the card, the driver
                          must verify their identity via the following method:
                        </div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row">
                          <div className="p-2 mr-2 bg-gray-200 rounded-md h-fit">
                            <ChatBubbleOvalLeftEllipsisIcon
                              className="w-10 h-10"
                              aria-hidden="true"
                            ></ChatBubbleOvalLeftEllipsisIcon>
                          </div>
                          <div className="">
                            <div className="font-medium">Unlock via SMS</div>
                            <div className="">
                              Text the last 4 digits of your card followed by
                              the expiry date in MMYY format (optional) and
                              Driver Tag ID (optional) separated by a comma to
                              (315) 888-5469. The card will be unlocked for 15
                              minutes. Some acceptable formats are:
                              <ul className="mt-2 list-disc list-inside">
                                <li>4575 (Only last four digits provided)</li>
                                <li>
                                  4575, 1228 (Only last four digits and MMYY
                                  provided)
                                </li>
                                <li>
                                  4575, 1228, 1ABC234 (Last four digits, MMYY
                                  and Driver Tag ID provided)
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="ml-3 text-base">
                          <FuelCardComponentPermissionGuard
                            type="inputswitch"
                            featurePermission={access.TransactionControlsAccess}
                            messageType="premium"
                          >
                            <InputSwitch
                              checked={values.smsSecurity}
                              name="smsSecurity"
                              id="smsSecurity"
                              disabled={!editCardRules}
                              onChange={handleChange}
                              className="ml-4 border-0 input-switch disabled:opacity-50"
                            />
                            {values.smsSecurity && (
                              <div className="ml-2">Active</div>
                            )}
                            {!values.smsSecurity && (
                              <div className="ml-2">Inactive</div>
                            )}
                          </FuelCardComponentPermissionGuard>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 text-xs italic text-gray-600">
                      A vehicle needs to be linked to the card to activate this
                      feature
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
              <div className="flex flex-col gap-4 overflow-hidden md:gap-8">
                <div className="px-4 font-medium border-gray-300">
                  <div className="font-semibold text-brown-500 md:text-lg">
                    Transaction Controls{" "}
                    <span className="font-normal text-yellow-600">
                      (Premium)
                    </span>
                  </div>
                </div>
                <div className="h-0.5 border-0 bg-brown-500"></div>
                <div className="flex flex-col px-4 divide-y divide-gray-300">
                  {cardSharingControls === ENUM_CARD_SHARING.NONE && (
                    <div className="flex flex-row justify-between pb-4">
                      <div className="flex flex-col">
                        <div>
                          Card unlock through SMS or driver App{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          Ensure card can only be used by a verified driver
                        </div>
                        <div className="mt-2 text-xs italic text-gray-600">
                          A verified driver needs to be linked to the card to
                          activate this feature
                        </div>
                        <div className="mt-2 text-sm">
                          <Button
                            onClick={() => {
                              settooltip(true);
                            }}
                            variant={ButtonVariant.Transparent}
                            size={ButtonSize.Wrap}
                            className="text-orange-500"
                          >
                            {/* <ExclamationCircleIcon
                          className="w-5 h-5 text-yellow-600"
                          aria-hidden="true"
                        ></ExclamationCircleIcon> */}
                            How it works?
                          </Button>
                          {tooltip && (
                            <InfoModal
                              title={"Card Unlock Security"}
                              body={
                                <div className="text-sm md:text-base">
                                  <div className="mb-3">
                                    <div className="mb-5">
                                      By enabling, only verified users will be
                                      able to use this card, so make sure they
                                      are added on the driver&apos;s page.
                                      Before using the card, drivers must verify
                                      their identity via the following method:
                                    </div>
                                  </div>
                                  <div className="flex flex-row">
                                    <div className="p-2 mr-2 bg-gray-200 rounded-md h-fit">
                                      <ChatBubbleOvalLeftEllipsisIcon
                                        className="w-10 h-10"
                                        aria-hidden="true"
                                      ></ChatBubbleOvalLeftEllipsisIcon>
                                    </div>
                                    <div>
                                      <div className="font-medium">
                                        Unlock via SMS
                                      </div>
                                      <div className="">
                                        Text the last 4 digits of your card to
                                        (315) 888-5469. The card will be
                                        unlocked for 15 minutes.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                              setShow={settooltip}
                              show={tooltip}
                            ></InfoModal>
                          )}
                        </div>
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="inputswitch"
                          featurePermission={access.TransactionControlsAccess}
                        >
                          <InputSwitch
                            checked={values.smsSecurity}
                            name="smsSecurity"
                            id="smsSecurity"
                            disabled={!editCardRules}
                            onChange={handleChange}
                            className="ml-4 border-0 input-switch disabled:opacity-50"
                          />
                        </FuelCardComponentPermissionGuard>
                        {values.smsSecurity && (
                          <div className="ml-2">Active</div>
                        )}
                        {!values.smsSecurity && (
                          <div className="ml-2">Inactive</div>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className={`flex flex-row justify-between ${
                      cardSharingControls === ENUM_CARD_SHARING.NONE
                        ? "py-4"
                        : "pb-4"
                    }`}
                  >
                    <div className="flex flex-col">
                      <div className="">
                        Require an odometer reading before every fuel
                        transaction{" "}
                        <span className="font-normal text-yellow-600">
                          (Premium)
                        </span>
                      </div>
                      <div className="mt-2 text-sm">
                        Odometer reading needs to be submitted to unlock the
                        card
                      </div>
                      <div className="mt-2 text-xs italic text-gray-600">
                        A verified driver needs to be linked to the card to
                        activate this feature
                      </div>
                      {errors.odometerSecurity && (
                        <div className="mt-2 text-sm text-red-500">
                          {errors.odometerSecurity}
                        </div>
                      )}
                    </div>
                    <div className="text-base">
                      <FuelCardComponentPermissionGuard
                        type="inputswitch"
                        featurePermission={access.TransactionControlsAccess}
                      >
                        <InputSwitch
                          checked={values.odometerSecurity}
                          name="odometerSecurity"
                          id="odometerSecurity"
                          disabled={!editCardRules}
                          onChange={handleChange}
                          className="ml-4 border-0 input-switch disabled:opacity-50"
                        />
                        {values.odometerSecurity && (
                          <div className="ml-2">Active</div>
                        )}
                        {!values.odometerSecurity && (
                          <div className="ml-2">Inactive</div>
                        )}
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between py-4">
                    <div className="flex flex-col">
                      <div className="">
                        Require a transaction tag ID{" "}
                        <span className="font-normal text-yellow-600">
                          (Premium)
                        </span>
                      </div>
                      <div className="mt-2 text-sm">
                        Drivers will be prompted for a transaction tag ID in the
                        driver app after the card is swiped
                      </div>
                      <div className="mt-2 text-xs italic text-gray-600">
                        A driver needs to be linked to the card to activate this
                        feature
                      </div>
                    </div>
                    <div className="text-base">
                      <FuelCardComponentPermissionGuard
                        type="inputswitch"
                        featurePermission={access.TransactionControlsAccess}
                      >
                        <InputSwitch
                          checked={values.requireJobID}
                          name="requireJobID"
                          id="requireJobID"
                          disabled={!editCardRules}
                          onChange={handleChange}
                          className="ml-4 border-0 input-switch disabled:opacity-50"
                        />
                        {values.requireJobID && (
                          <div className="ml-2">Active</div>
                        )}
                        {!values.requireJobID && (
                          <div className="ml-2">Inactive</div>
                        )}
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between py-4">
                    <div className="flex flex-col">
                      <div className="">
                        Enable receipt upload via SMS{" "}
                        <span className="font-normal text-yellow-600">
                          (Premium)
                        </span>
                      </div>
                      <div className="mt-2 text-sm">
                        Drivers will receive a text message after the card is
                        swiped
                      </div>
                      <div className="mt-2 text-xs italic text-gray-600">
                        A driver needs to be linked to the card to activate this
                        feature
                      </div>
                      {errors.enableSmsReceiptUpload && (
                        <div className="mt-2 text-sm text-red-500">
                          {errors.enableSmsReceiptUpload}
                        </div>
                      )}
                    </div>
                    <div className="text-base">
                      <FuelCardComponentPermissionGuard
                        type="inputswitch"
                        featurePermission={access.TransactionControlsAccess}
                      >
                        <InputSwitch
                          checked={values.enableSmsReceiptUpload}
                          name="enableSmsReceiptUpload"
                          id="enableSmsReceiptUpload"
                          disabled={!editCardRules}
                          onChange={handleChange}
                          className="ml-4 border-0 input-switch disabled:opacity-50"
                        />
                        {values.enableSmsReceiptUpload && (
                          <div className="ml-2">Active</div>
                        )}
                        {!values.enableSmsReceiptUpload && (
                          <div className="ml-2">Inactive</div>
                        )}
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between py-4">
                    <div className="flex flex-col">
                      <div className="">
                        Flag for wrong fuel type{" "}
                        <span className="font-normal text-yellow-600">
                          (Premium)
                        </span>
                      </div>
                      <div className="mt-2 text-sm">
                        Transactions will be flagged if the purchased fuel type
                        doesn't match the fuel type in vehicle settings
                      </div>
                      <div className="mt-2 text-xs italic text-gray-600">
                        A vehicle with a specified fuel type needs to be linked
                        to the card to activate this feature
                      </div>
                    </div>
                    <div className="text-base">
                      <FuelCardComponentPermissionGuard
                        type="inputswitch"
                        featurePermission={access.TransactionControlsAccess}
                      >
                        <InputSwitch
                          checked={values.fuelTypeSecurity}
                          name="fuelTypeSecurity"
                          id="fuelTypeSecurity"
                          disabled={!editCardRules}
                          onChange={handleChange}
                          className="ml-4 border-0 input-switch disabled:opacity-50"
                        />
                        {values.fuelTypeSecurity && (
                          <div className="ml-2">Active</div>
                        )}
                        {!values.fuelTypeSecurity && (
                          <div className="ml-2">Inactive</div>
                        )}
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between py-4">
                    <div className="flex flex-col">
                      <div className="">
                        Flag when fuel purchase exceeds tank capacity{" "}
                        <span className="font-normal text-yellow-600">
                          (Premium)
                        </span>
                      </div>
                      <div className="mt-2 text-sm">
                        Transactions will be flagged if the fuel purchased is
                        greater than the vehicle tank capacity
                      </div>
                      <div className="mt-2 text-xs italic text-gray-600">
                        A vehicle with a specified tank capacity needs to be
                        linked to the card to activate this feature
                      </div>
                    </div>
                    <div className="text-base">
                      <FuelCardComponentPermissionGuard
                        type="inputswitch"
                        featurePermission={access.TransactionControlsAccess}
                      >
                        <InputSwitch
                          checked={values.tankCapacitySecurity}
                          name="tankCapacitySecurity"
                          id="tankCapacitySecurity"
                          disabled={!editCardRules}
                          onChange={handleChange}
                          className="ml-4 border-0 input-switch disabled:opacity-50"
                        />
                        {values.tankCapacitySecurity && (
                          <div className="ml-2">Active</div>
                        )}
                        {!values.tankCapacitySecurity && (
                          <div className="ml-2">Inactive</div>
                        )}
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between py-4">
                    <div className="flex flex-col">
                      <div className="">
                        Flag when fuel level anomaly is detected/fuel theft
                        detected{" "}
                        <span className="font-normal text-yellow-600">
                          (Premium)
                        </span>
                      </div>
                      <div className="mt-2 text-sm">
                        Transactions will be flagged if the fuel level within
                        the vehicle drops beyond an acceptable limit between 2
                        consecutive vehicle fueling
                      </div>
                      <div className="mt-2 text-xs italic text-gray-600">
                        A vehicle with a specified tank capacity needs to be
                        linked to the card to activate this feature
                      </div>
                    </div>
                    <div className="text-base">
                      <FuelCardComponentPermissionGuard
                        type="inputswitch"
                        featurePermission={access.TransactionControlsAccess}
                      >
                        <InputSwitch
                          checked={values.suspiciousFuelingSecurity}
                          name="suspiciousFuelingSecurity"
                          id="suspiciousFuelingSecurity"
                          disabled={!editCardRules}
                          onChange={handleChange}
                          className="ml-4 border-0 input-switch disabled:opacity-50"
                        />
                        {values.suspiciousFuelingSecurity && (
                          <div className="ml-2">Active</div>
                        )}
                        {!values.suspiciousFuelingSecurity && (
                          <div className="ml-2">Inactive</div>
                        )}
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between gap-3 py-4">
                    <div className="flex flex-col">
                      <div className="">
                        Flag transactions from specific merchant categories{" "}
                        <span className="font-normal text-yellow-600">
                          (Premium)
                        </span>
                      </div>
                    </div>
                    <div className="text-base">
                      <FuelCardComponentPermissionGuard
                        type="checkbox"
                        featurePermission={access.TransactionControlsAccess}
                        messageType="premium"
                      >
                        <CustomMultiSelect
                          value={values.flaggedMerchantCategories}
                          className="max-w-[180px] md:max-w-auto"
                          options={[
                            {
                              label: "-- Select multiple options --",
                              value: "",
                              disabled: true,
                            },
                            {
                              label: "auto_service_shops",
                              value: "auto_service_shops",
                              disabled: false,
                            },
                          ].concat(
                            CATEGORIES_MASTER.split(",").map((categories) => {
                              return {
                                label: categories,
                                value: categories,
                                disabled: false,
                              };
                            }),
                          )}
                          onChange={(e) =>
                            setFieldValue("flaggedMerchantCategories", e.value)
                          }
                          placeholder="-- Select multiple options --"
                          optionLabel="label"
                          optionValue="value"
                          maxSelectedLabels={1}
                          disabled={!editCardRules}
                        ></CustomMultiSelect>
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between pt-4">
                    <div className="flex flex-col">
                      <div className="">
                        Alert/Decline transaction when vehicle is far away from
                        the merchant{" "}
                        <span className="font-normal text-yellow-600">
                          (Premium)
                        </span>
                      </div>
                      <div className="mt-2 text-sm">
                        Connect a telematics provider to get started
                      </div>
                      <div className="mt-2 text-xs italic text-gray-600">
                        A vehicle with telematics integration needs to be linked
                        to the card to activate this feature
                      </div>
                    </div>
                    <div className="text-base">
                      <FuelCardComponentPermissionGuard
                        type="dropdown"
                        featurePermission={access.TransactionControlsAccess}
                      >
                        <Select
                          value={values.telematicsSecurity}
                          disabled={!editCardRules}
                          onChange={(e) =>
                            setFieldValue("telematicsSecurity", e.value)
                          }
                          onBlur={handleBlur}
                          name="telematicsSecurity"
                          id="telematicsSecurity"
                          className="min-w-[150px]"
                          options={[
                            {
                              label: "--- Select ---",
                              value: "",
                              disabled: true,
                            },
                          ].concat(
                            Object.keys(ENUM_TELEMATICS_SECURITY)?.map(
                              (telemetics) => {
                                if (
                                  telemetics ===
                                  ENUM_TELEMATICS_SECURITY?.BLOCK_BROAD_LOCATION
                                ) {
                                  return {
                                    label: "Block by Postal Code, City, State",
                                    value: telemetics,
                                    disabled: false,
                                  };
                                }
                                return {
                                  label: humanizeEnum(telemetics),
                                  value: telemetics,
                                  disabled: false,
                                };
                              },
                            ),
                          )}
                        ></Select>
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {createMode && (
                <div className="fixed flex flex-row justify-end space-y-2 bottom-5 right-2">
                  <Button
                    variant={ButtonVariant.Green}
                    size={ButtonSize.Wrap}
                    className="flex items-center justify-center w-12 h-12 rounded-full"
                    disabled={submittingRequest}
                    onClick={() => {
                      setTabOpen(2);
                    }}
                  >
                    <ArrowRightIcon className="w-6 h-6" />
                  </Button>
                </div>
              )}

              {!createMode && !editCardRules && (
                <div className="fixed flex flex-row justify-end space-y-2 bottom-5 right-5">
                  <Button
                    type={ButtonType.Button}
                    size={ButtonSize.AppSize}
                    variant={ButtonVariant.Green}
                    disabled={submittingRequest}
                    onClick={() => {
                      setEditCardRules(true);
                    }}
                  >
                    <PencilIcon className="w-5 h-5" />
                    <span className="pl-2 text-base">Edit</span>
                  </Button>
                </div>
              )}
              {!createMode && editCardRules && (
                <div className="fixed flex flex-row justify-end gap-2 bottom-5 right-5">
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Red}
                    size={ButtonSize.AppSize}
                    disabled={submittingRequest}
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Black}
                    size={ButtonSize.AppSize}
                    disabled={submittingRequest}
                    loading={submittingRequest}
                    onClick={() => {
                      validateAndSubmit({ createNew: false });
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}

        {access.SpendLimitsAccess && (
          <div className={`${tabOpen === 2 ? `block` : `hidden`} mt-4 md:mt-6`}>
            <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
              <div className="flex flex-col gap-4 overflow-hidden md:gap-8">
                <div className="px-4 font-medium border-gray-300">
                  <div className="font-semibold text-brown-500 md:text-lg">
                    Spend Limits
                  </div>
                </div>
                <div className="h-0.5 border-0 bg-brown-500"></div>
                <div className="flex flex-col px-4 divide-y divide-gray-300">
                  <div className="flex flex-row items-center justify-between pb-4 border-gray-300">
                    <div className="min-w-[125px]">Per Transaction Limit</div>
                    <div className="text-right">
                      <FuelCardComponentPermissionGuard
                        type="input"
                        featurePermission={access.LimitsAccess}
                      >
                        <TextInput
                          type="number"
                          className="max-w-[205px]"
                          min={0}
                          name="transactionLimit"
                          id="transactionLimit"
                          value={values.transactionLimit}
                          onChange={handleChange}
                          disabled={!editLimits}
                          onBlur={handleBlur}
                          placeholder="1000"
                          touched={touched.transactionLimit}
                          error={
                            touched.transactionLimit &&
                            Boolean(errors.transactionLimit)
                          }
                          errorMessage={errors.transactionLimit}
                        ></TextInput>
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between py-4 border-gray-300">
                    <div className="min-w-[125px]">Daily Limit</div>
                    <div className="text-right">
                      <FuelCardComponentPermissionGuard
                        type="input"
                        featurePermission={access.LimitsAccess}
                      >
                        <TextInput
                          type="number"
                          className="max-w-[205px]"
                          min={0}
                          name="dailyLimit"
                          id="dailyLimit"
                          value={values.dailyLimit}
                          onChange={handleChange}
                          disabled={!editLimits}
                          onBlur={handleBlur}
                          placeholder="100"
                          touched={touched.dailyLimit}
                          error={
                            touched.dailyLimit && Boolean(errors.dailyLimit)
                          }
                          errorMessage={errors.dailyLimit}
                        ></TextInput>
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between py-4 border-gray-300">
                    <div className="min-w-[125px]">Weekly Limit</div>
                    <div className="text-right">
                      <FuelCardComponentPermissionGuard
                        type="input"
                        featurePermission={access.LimitsAccess}
                      >
                        <TextInput
                          type="number"
                          className="max-w-[205px]"
                          min={0}
                          name="weeklyLimit"
                          id="weeklyLimit"
                          value={values.weeklyLimit}
                          onChange={handleChange}
                          disabled={!editLimits}
                          onBlur={handleBlur}
                          placeholder="1000"
                          touched={touched.weeklyLimit}
                          error={
                            touched.weeklyLimit && Boolean(errors.weeklyLimit)
                          }
                          errorMessage={errors.weeklyLimit}
                        ></TextInput>
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between pt-4 border-gray-300">
                    <div className="min-w-[125px]">Monthly Limit</div>
                    <div className="capitalize">
                      <FuelCardComponentPermissionGuard
                        type="input"
                        featurePermission={access.LimitsAccess}
                      >
                        <TextInput
                          type="number"
                          className="max-w-[205px]"
                          min={0}
                          name="monthlyLimit"
                          id="monthlyLimit"
                          value={values.monthlyLimit}
                          onChange={handleChange}
                          disabled={!editLimits}
                          onBlur={handleBlur}
                          placeholder="5000"
                          touched={touched.monthlyLimit}
                          error={
                            touched.monthlyLimit && Boolean(errors.monthlyLimit)
                          }
                          errorMessage={errors.monthlyLimit}
                        ></TextInput>
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
              <div className="flex flex-col overflow-hidden">
                <div className="flex flex-row items-center justify-between gap-2 px-4 mb-4 border-gray-300 md:mb-8">
                  <div className="pr-12">
                    <div className="font-semibold text-brown-500 md:text-lg">
                      Category Restrictions{" "}
                      <span className="font-normal text-yellow-600">
                        {" "}
                        (Premium)
                      </span>
                    </div>
                    <div className="mt-2 text-sm">
                      Block or restrict spending on this card to specific
                      merchant categories. These categories are based on
                      Merchant Category Codes, which are determined by the
                      merchant and confirmed by the Visa payments network.
                      Merchant categories could be miscategorized. This is
                      outside of RoadFlex's control.
                    </div>
                  </div>
                  <div className="flex items-center h-6 pl-4">
                    <InputSwitch
                      id="overallToggle"
                      name="overallToggle"
                      checked={overallToggle}
                      disabled={!editLimits}
                      onChange={(e) => handleOverallToggle(e.value)} // Handle overall switch toggle
                      className="border-0 input-switch"
                    />
                  </div>
                </div>
                <div className="h-0.5 border-0 bg-brown-500"></div>
                <div className="px-4">
                  <div className="space-y-0">
                    {fuelExpensesCategories?.length > 0 && (
                      <>
                        <div className="pt-4 text-left md:pt-8">
                          Fuel Expenses
                        </div>
                        {fuelExpensesCategories.map((merchantType, index) => (
                          <div key={index}>
                            <PolicyMerchantDisclosureUI
                              {...{
                                access: access.CategoryRestrictionsAccess,
                                editLimits,
                                merchantType,
                                handleChange,
                                resetForm,
                                handleBlur,
                                values,
                                touched,
                                errors,
                                setFieldValue,
                                validateForm,
                                ...rest,
                              }}
                            ></PolicyMerchantDisclosureUI>
                          </div>
                        ))}
                      </>
                    )}

                    {vehicleExpensesCategories?.length > 0 && (
                      <>
                        <div className="pt-4 text-left md:pt-8">
                          Vehicle Expenses
                        </div>
                        {vehicleExpensesCategories.map(
                          (merchantType, index) => (
                            <div key={index}>
                              <PolicyMerchantDisclosureUI
                                {...{
                                  access: access.CategoryRestrictionsAccess,
                                  editLimits,
                                  merchantType,
                                  handleChange,
                                  resetForm,
                                  handleBlur,
                                  values,
                                  touched,
                                  errors,
                                  setFieldValue,
                                  validateForm,
                                  ...rest,
                                }}
                              ></PolicyMerchantDisclosureUI>
                            </div>
                          ),
                        )}
                      </>
                    )}
                    {businessExpensesCategories.length > 0 && (
                      <>
                        <div className="pt-4 text-left md:pt-8">
                          Business Expenses
                        </div>
                        {businessExpensesCategories.map(
                          (merchantType, index) => (
                            <div key={index}>
                              <PolicyMerchantDisclosureUI
                                {...{
                                  access: access.CategoryRestrictionsAccess,
                                  editLimits,
                                  merchantType,
                                  handleChange,
                                  resetForm,
                                  handleBlur,
                                  values,
                                  touched,
                                  errors,
                                  setFieldValue,
                                  validateForm,
                                  ...rest,
                                }}
                              ></PolicyMerchantDisclosureUI>
                            </div>
                          ),
                        )}
                      </>
                    )}
                    {otherCategories?.length > 0 && (
                      <>
                        <div className="w-full">
                          <div className="pt-8 font-medium text-left">
                            Additional Merchants Enabled
                          </div>
                          <div className="mt-2 text-xs italic text-left text-gray-500">
                            Additional merchant types that are opened up for
                            your account your account
                          </div>
                        </div>
                        {otherCategories.map((merchantType, index) => (
                          <div key={index}>
                            <PolicyMerchantDisclosureUI
                              {...{
                                access: access.CategoryRestrictionsAccess,
                                editLimits,
                                merchantType,
                                handleChange,
                                resetForm,
                                handleBlur,
                                values,
                                touched,
                                errors,
                                setFieldValue,
                                validateForm,
                                ...rest,
                              }}
                            ></PolicyMerchantDisclosureUI>
                          </div>
                        ))}
                        {otherMerchantCategories.map((merchantType, index) => (
                          <div key={index}>
                            <PolicyMerchantDisclosureUI
                              {...{
                                access: access.CategoryRestrictionsAccess,
                                editLimits,
                                merchantType,
                                handleChange,
                                resetForm,
                                handleBlur,
                                values,
                                touched,
                                errors,
                                setFieldValue,
                                validateForm,
                                ...rest,
                              }}
                            ></PolicyMerchantDisclosureUI>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              {createMode && (
                <div className="fixed flex flex-row justify-end space-y-2 bottom-5 right-2">
                  <Button
                    variant={ButtonVariant.Green}
                    size={ButtonSize.Wrap}
                    className="flex items-center justify-center w-12 h-12 rounded-full"
                    disabled={submittingRequest}
                    onClick={() => {
                      setTabOpen(3);
                    }}
                  >
                    <ArrowRightIcon className="w-6 h-6" />
                  </Button>
                </div>
              )}
              {!createMode && !editLimits && (
                <div className="fixed flex flex-row justify-end space-y-2 bottom-5 right-5">
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Green}
                    size={ButtonSize.AppSize}
                    disabled={submittingRequest}
                    onClick={() => {
                      setEditLimits(true);
                    }}
                  >
                    <PencilIcon className="w-5 h-5" />
                    <span className="pl-2 text-base">Edit</span>
                  </Button>
                </div>
              )}
              {!createMode && editLimits && (
                <div className="fixed flex flex-row justify-end gap-2 bottom-5 right-5">
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Red}
                    size={ButtonSize.AppSize}
                    disabled={submittingRequest}
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Black}
                    size={ButtonSize.AppSize}
                    disabled={submittingRequest}
                    loading={submittingRequest}
                    onClick={() => {
                      validateAndSubmit({ createNew: false });
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        <div className={`${tabOpen === 3 ? `block` : `hidden`} mt-4 md:mt-6`}>
          <DynamicPurchaseControls
            object="policy"
            id={values.id}
            access={access.DynamicPurchaseControl}
            createMode={createMode}
            enableEdit={true}
            createNewFunction={validateAndSubmit}
          ></DynamicPurchaseControls>
        </div>
      </form>
    </div>
  );
};
