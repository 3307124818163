/* eslint-disable dot-notation */
/** @format */
import { useMutation, useQuery } from "@apollo/client";
import { appVehicleValidationSchema } from "@roadflex/constants";
import {
  CREATE_VEHICLE,
  DELETE_VEHICLE,
  READ_VEHICLES,
  UPDATE_VEHICLE,
} from "@roadflex/graphql";
import { FleetExtended, IdListInputType, VehicleType } from "@roadflex/types";
import { useFormik } from "formik";

type useCreateVehicleProps = {
  initialValues: VehicleType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type useUpdateVehicleProps = {
  initialValues: VehicleType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type useDeleteVehiclesProps = {
  initialValues: IdListInputType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

export const useCreateFleet = ({
  onSubmit,
  initialValues,
}: useCreateVehicleProps) => {
  const [createVehicleFn] = useMutation<{
    createVehicle: {
      code: string;
      message: string;
    };
  }>(CREATE_VEHICLE);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    validationSchema: appVehicleValidationSchema,
    onSubmit: async (value) => {
      const { autoGeneratePromptCode, ...rest } = value;
      const data = {
        ...rest,
      };

      try {
        const res = await createVehicleFn({
          variables: { data },
        });
        if (res?.data?.createVehicle) {
          onSubmit(res?.data?.createVehicle, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};
export function useReadVehicles(idList: string[]) {
  const {
    data: getVehiclesData,
    refetch: getVehiclesRefetch,
    loading: getVehiclesLoading,
    ...rest
  } = useQuery<{
    readVehicles: {
      code: string;
      message: string;
      vehicles: FleetExtended[];
    };
  }>(READ_VEHICLES, {
    fetchPolicy: "no-cache",
    variables: {
      data: { idList: idList },
    },
    notifyOnNetworkStatusChange: true,
  });
  return {
    getVehiclesData,
    getVehiclesRefetch,
    getVehiclesLoading,
    ...rest,
  };
}
export const useUpdateVehicle = ({
  onSubmit,
  initialValues,
}: useUpdateVehicleProps) => {
  const [updateDriversFn] = useMutation<{
    updateVehicle: {
      code: string;
      message: string;
    };
  }>(UPDATE_VEHICLE, {
    refetchQueries: [READ_VEHICLES],
  });

  const { ...restFormikValues } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: appVehicleValidationSchema,
    onSubmit: async (value) => {
      try {
        const data: VehicleType = {
          fleetName: value.fleetName,
          vin: value.vin,
          make: value.make,
          model: value.model,
          year: value.year,
          licensePlate: value.licensePlate,
          odometer: value.odometer,
          provisionState: value.provisionState,
          fuelType: value.fuelType,
          tankCapacity: Number(value.tankCapacity),
          id: value.id,
          promptCode: value.promptCode,
        };
        const res = await updateDriversFn({
          variables: {
            data,
          },
        });
        if (res?.data?.updateVehicle) {
          onSubmit(res?.data?.updateVehicle, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    ...restFormikValues,
  };
};
export const useDeleteVehicles = ({
  onSubmit,
  initialValues,
}: useDeleteVehiclesProps) => {
  const [deleteFleetFn] = useMutation<{
    deleteVehicles: {
      code: string;
      message: string;
    };
  }>(DELETE_VEHICLE, {
    refetchQueries: [READ_VEHICLES],
  });

  const {
    handleSubmit: deleteVehiclesHandleSubmit,
    values: deleteVehiclesValues,
    isSubmitting: deleteVehiclesIsSubmitting,
    setValues: deleteVehiclesSetValues,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await deleteFleetFn({
          variables: {
            data: { idList: value.idList },
          },
        });
        if (res?.data?.deleteVehicles) {
          onSubmit(res?.data?.deleteVehicles, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });
  return {
    deleteVehiclesHandleSubmit,
    deleteVehiclesValues,
    deleteVehiclesIsSubmitting,
    deleteVehiclesSetValues,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};
