/** @format */

import {
  LifebuoyIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ENUM_ACCOUNT_TAG_TYPE } from "@prisma/client";
import { STRIPE_CARD_PROFILES } from "@roadflex/constants";
import { usePlatformDetect, useRemoveCardPolicy } from "@roadflex/react-hooks";
import {
  AccountTagType,
  Card,
  CardShortened,
  DriversFilterOptions,
  ParameterFilterType,
  ReadAppCard,
  UserAuthType,
  VehiclesFilterOptions,
} from "@roadflex/types";
import { convertISOStringToDate } from "@roadflex/utils";
import { getCookie, setCookie } from "@roadflex/web-lib";
import classNames from "classnames";
import { useRouter } from "next/router";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable, DataTableRowToggleParams } from "primereact/datatable";
import { OverlayPanel } from "primereact/overlaypanel";
import { SetStateAction, useEffect, useState } from "react";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from "react-joyride";
import { Button, ButtonLink, ButtonSize, ButtonVariant } from "../../buttons";
import {
  cardNumberTemplate,
  cardsAlreadyDeliveredActionTemplate,
  smsSecurityTemplate,
  statusTemplate,
} from "../../datatable/cards-templates/cards-templates";
import {
  dateOnlyTemplate,
  driverFilterTemplate,
  vehicleFilterTemplate,
} from "../../datatable/common-templates/common-templates";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";
import { AssignAccountTagModal, ConfirmModal, InfoModal } from "../../modals";
import RemovePolicySettingModal from "../../modals/remove-policy-setting-modal/remove-policy-setting-modal";
import { Toast } from "../../toast-message/toast";
import { PlacedCardOrdersUI } from "./placed-order-card.ui";

type cardsUIProps = {
  readAppCardData: ReadAppCard;
  readAppCardDataLoading: boolean;
  readAppCardDataRefetch: () => void;
  setcardPopup: (value: boolean) => void;
  deliveredCards: CardShortened[];
  op: React.RefObject<OverlayPanel>;
  submittingRequest: boolean;
  cardPopup: boolean;
  currentVehiclesFilter: VehiclesFilterOptions[];
  currentDriversFilter: DriversFilterOptions[];
  selectedCards: CardShortened[];
  setSelectedCards: (value: SetStateAction<CardShortened[]>) => void;
  handleMultipleCardsStatusUpdate: (
    cards: CardShortened[],
    cardStatus: string,
  ) => void;
  proceedToHandle: (cardStatus: string, cards: CardShortened[]) => boolean;
  deliveryDetails: {
    deliveryService: string;
    deliveryPartner: string;
    deliveryStatus: string;
    deliveryType: string;
    eta: Date;
    trackingNumber: string;
    trackingUrl: string;
  };
  setDeliveryDetails: (value: {
    deliveryService: string;
    deliveryPartner: string;
    deliveryStatus: string;
    deliveryType: string;
    eta: Date;
    trackingNumber: string;
    trackingUrl: string;
  }) => void;
  showDeliveryDetails: boolean;
  setShowDeliveryDetails: (value: boolean) => void;
  showConfirmation: boolean;
  setConfirmation: (value: boolean) => void;
  variables: { var1: number; var2: number; var3: number };
  readCurrentUserAuth: UserAuthType;
  cardTags: ParameterFilterType[];
};

interface JoyrideState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
}

export const CardsUI = ({
  readAppCardDataLoading,
  readAppCardDataRefetch,
  readAppCardData,
  setcardPopup,
  deliveredCards,
  op,
  submittingRequest,
  cardPopup,
  proceedToHandle,
  currentVehiclesFilter,
  currentDriversFilter,
  selectedCards,
  setSelectedCards,
  handleMultipleCardsStatusUpdate,
  deliveryDetails,
  setDeliveryDetails,
  showDeliveryDetails,
  setShowDeliveryDetails,
  showConfirmation,
  setConfirmation,
  variables,
  readCurrentUserAuth,
  cardTags,
}: cardsUIProps) => {
  const [form, setform] = useState<{ deliveryType: string | undefined }>({
    deliveryType: readAppCardData?.business.deliveryType,
  });

  const [expandedRows, setExpandedRows] = useState<CardShortened[]>([]);
  const [openAssignAccountTagModal, setOpenAssignAccountTagModal] =
    useState(false);
  const [openRemovePolicySettingModal, setOpenRemovePolicySettingModal] =
    useState<{ open: boolean; row: CardShortened | null }>({
      open: false,
      row: null,
    });
  const [selectedAccountTag, setSelectedAccountTag] = useState<
    undefined | AccountTagType
  >();
  const [itemName, setItemName] = useState<string>("");
  const [itemId, setItemId] = useState<string>("");

  const { isMobile } = usePlatformDetect();
  const router = useRouter();
  const { handleRemoveCardPolicy, removeCardPolicyLoading } =
    useRemoveCardPolicy({
      initialValues: { cardId: "" },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSubmit: (data: any, error: any) => {
        if (data) {
          Toast({
            type: "success",
            message: "Policy removed successfully",
          });
          setOpenRemovePolicySettingModal({ open: false, row: null });
          readAppCardDataRefetch();
        }
        if (error) {
          Toast({
            type: "error",
            message: "Something Went Wrong",
          });
        }
      },
    });

  const ACCOUNT_TAGS_COLOR = [
    "bg-slate-200",
    "bg-red-200",
    "bg-amber-200",
    "bg-green-200",
    "bg-teal-200",
    "bg-gray-200",
    "bg-purple-200",
    "bg-blue-200",
    "bg-lime-200",
    "bg-orange-200",
    "bg-sky-200",
    "bg-pink-200",
  ];

  const [{ run, stepIndex, steps }, setJoyride] = useState<JoyrideState>({
    run: false,
    stepIndex: 0,
    steps: [
      {
        title: <h1 className="text-lg font-semibold text-red-900">Welcome</h1>,
        content: "Welcome to the Cards page.",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
        placement: "center",
        target: "body",
      },
      {
        title: (
          <h1 className="text-lg font-semibold text-red-900">Card order</h1>
        ),
        content:
          "Click the card order form to order cards. You can choose standard or premium delivery.",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
        spotlightPadding: 10,
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        target: ".step-2",
      },
      {
        title: <h1 className="text-lg font-semibold text-red-900">Cards</h1>,
        content:
          "For recently ordered cards, click on the information button to view delivery details. For security reasons, activate your cards only after receiving them. Only enterprise accounts are able to cancel cards.",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
        spotlightPadding: 10,
        target: ".step-3",
      },
      {
        title: (
          <h1 className="text-lg font-semibold text-red-900">Customize</h1>
        ),
        content:
          "Customize the settings of each card by clicking the arrow button.",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
        spotlightPadding: 10,
        target: ".step-4",
      },
      {
        target: ".step-5",
        title: (
          <h1 className="text-lg font-semibold text-red-900">Help Center</h1>
        ),
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
          last: "Close",
        },
        spotlightPadding: 10,
        content: (
          <p className="text-base text-gray-700">
            If you need help or would like to go through the navigation again,
            click <em>Help Center</em>.
          </p>
        ),
      },
    ],
  });

  const accountTagTemplate = (rowData: CardShortened) => {
    let colorCode = "bg-white";
    if (rowData.accountTag) {
      const index = Number(rowData.accountTag?.colorCode) || 0;
      const length = ACCOUNT_TAGS_COLOR.length;
      colorCode = ACCOUNT_TAGS_COLOR[index % length];
    }
    return (
      <div className="flex gap-2">
        <span
          className={classNames(
            colorCode,
            "rounded-md px-1.5 py-0.5 whitespace-nowrap text-ellipsis",
          )}
        >
          {rowData?.accountTag?.title || "-"}
        </span>
        {cardTags?.length === 0 && (
          <Button
            variant={ButtonVariant.Transparent}
            size={ButtonSize.Wrap}
            className="flex items-center w-4 h-4"
            onClick={() => {
              setSelectedAccountTag(rowData?.accountTag);
              setItemId(rowData.id);
              setItemName(rowData.lastFour);
              setOpenAssignAccountTagModal(true);
            }}
          >
            <PencilIcon className="w-4 h-4" />
          </Button>
        )}
      </div>
    );
  };

  const removeSettingDailog = (rowData: CardShortened): void => {
    setOpenRemovePolicySettingModal({ open: true, row: rowData });
  };

  const metaDataBtn = (rowData: CardShortened) => {
    const JSONObject = JSON.parse(JSON.stringify(rowData?.metaData));
    if (JSONObject) {
      return (
        <Button
          variant={ButtonVariant.Transparent}
          size={ButtonSize.Wrap}
          className="flex items-center w-4 h-4"
          onClick={(e) => {
            e.preventDefault();
            removeSettingDailog(rowData);
          }}
        >
          <TrashIcon className="w-4 h-4" />
        </Button>
      );
    }
    return <></>;
  };
  const metaDataTemplate = (rowData: CardShortened) => {
    const JSONObject = JSON.parse(JSON.stringify(rowData?.metaData));
    return (
      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2">
          <div className="text-center">{JSONObject?.policyName || "-"}</div>
          <div className="flex justify-center">{metaDataBtn(rowData)}</div>
        </div>
        <div className="text-xs">
          {dateOnlyTemplate(
            JSONObject?.dateApplied,
            readCurrentUserAuth?.accountDisplaySetting?.timeZone,
          )}
        </div>
      </div>
    );
  };

  const stripeCardProgramTemplate = (rowData: CardShortened) => {
    const cardProfiles = STRIPE_CARD_PROFILES.filter(
      (x) => x.cardProfileType === rowData?.stripeCardProgram,
    );
    return (
      <span className="px-2 text-xs text-brown-500 bg-neutral-100 whitespace-nowrap">
        {cardProfiles[0]?.name}
      </span>
    );
  };

  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setJoyride({ run: true, stepIndex: 0, steps });
  };

  const confirmCardStatusChange = async () => {
    handleMultipleCardsStatusUpdate(selectedCards, "active");
    setConfirmation(false);
  };

  useEffect(() => {
    const cardsGuide = getCookie("cardsGuide");
    if (!cardsGuide) {
      setJoyride({ run: true, stepIndex, steps });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cardPopup && run) {
      setJoyride({ run: false, stepIndex, steps });
    }
    if (!cardPopup && stepIndex > 0) {
      setJoyride({ run: true, stepIndex: stepIndex + 1, steps });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardPopup]);

  const handleJoyrideCallback = (callbackData: CallBackProps) => {
    const { action, index, status, type } = callbackData;

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      setCookie("cardsGuide", "true", 30);
      setJoyride({ run: false, stepIndex, steps });
    } else if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setJoyride({
        stepIndex: nextStepIndex,
        run,
        steps,
      });
    }
  };

  type FilterProps = "driverName" | "vehicleName";
  const initFilterConfig: {
    [key in FilterProps]: {
      value: null | string;
      matchMode: FilterMatchMode;
    };
  } = {
    driverName: { value: null, matchMode: FilterMatchMode.IN },
    vehicleName: { value: null, matchMode: FilterMatchMode.IN },
  };
  const [cardsFilterConfig, setCardsFilterConfig] = useState<{
    [key in FilterProps]: {
      value: null | string;
      matchMode: FilterMatchMode;
    };
  }>(initFilterConfig);

  useEffect(() => {
    setform({
      deliveryType: readAppCardData?.business.deliveryType,
    });
  }, [readAppCardData]);

  const rowClass = (data: Card) => {
    return {
      "green-row": data.status === "active",
      "red-row": data.status === "inactive",
      "hover-row": true,
    };
  };

  const rowExpansionTemplate = (data: CardShortened) => {
    return (
      <div className="max-w-sm p-4 mx-auto bg-white border border-gray-300 rounded-md shadow-lg">
        <div className="flex flex-col gap-2 text-sm text-gray-600">
          <div>
            Driver: {data?.fleetDriver ? data?.fleetDriver[0]?.name : "-"}{" "}
            {/* Driver name */}
          </div>
          <div>
            Vehicle: {data?.fleet ? data?.fleet[0]?.fleetName : "-"}{" "}
            {/* Vehicle detail */}
          </div>
          <div className="flex items-center">
            Policy Applied: {metaDataTemplate(data)} {/* Merchant location */}
          </div>
          <div className="flex items-center">
            Account Tag: {accountTagTemplate(data)}
          </div>
          <div>Sms Security: {smsSecurityTemplate(data)}</div>
          <div className="flex items-center">
            Customize: {cardsAlreadyDeliveredActionTemplate(data)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="flex justify-between text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        <div>{`Cards`.toUpperCase()}</div>
        <div className="flex items-center justify-end rounded-md md:mt-0 step-6">
          <Button
            onClick={handleClickStart}
            variant={ButtonVariant.Transparent}
            size={ButtonSize.Wrap}
            className="step-5"
          >
            <LifebuoyIcon
              className="flex-shrink-0 w-4 h-4"
              aria-hidden="true"
            />
            <span className="px-1 text-xs italic">Help Center</span>
          </Button>
        </div>
      </div>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        stepIndex={stepIndex}
        run={run}
        scrollToFirstStep
        showProgress
        disableCloseOnEsc
        disableOverlayClose
        showSkipButton
        steps={steps}
        scrollOffset={76}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: "#000000",
          },
        }}
      />

      <div className="flex flex-col w-full p-4 mt-5 mr-4 bg-white rounded-md shadow md:p-8 gap-y-8 md:mt-0">
        <div className="flex flex-row justify-between w-full">
          <div>
            <div className="text-brown-500">Physical Cards</div>
            <div>
              {" "}
              {`${deliveredCards.length} cards (${
                deliveredCards.filter((card) => card.status === "active").length
              } active)`}
            </div>
          </div>
          <div>
            <Button
              onClick={() => {
                router.push("/card-order-form");
              }}
              variant={ButtonVariant.AppOrange}
              className="step-2"
              disabled={cardTags?.length !== 0}
              size={ButtonSize.AppSize}
            >
              Card Order Form
            </Button>
          </div>
        </div>
        <div className="flex flex-row justify-between w-full">
          <div>
            <div className="text-brown-500">Business Name on Card</div>
            <div>{readAppCardData?.business?.cardName.toUpperCase()}</div>
          </div>
          <div>
            <ButtonLink
              variant={ButtonVariant.AppOrange}
              size={ButtonSize.AppSize}
              href="/account-settings?tab=businessInfo"
              className=""
            >
              Update
            </ButtonLink>
          </div>
        </div>
      </div>
      <PlacedCardOrdersUI></PlacedCardOrdersUI>
      <div className="flex flex-col w-full p-4 mt-4 bg-white rounded-md shadow md:mt-8 md:p-8">
        <div className="flex flex-row justify-between w-full ">
          <Button
            size={ButtonSize.AppSize}
            variant={ButtonVariant.AppOrange}
            disabled={
              selectedCards.filter((card) => card.status === "active")
                .length === 0 || submittingRequest
            }
            onClick={() =>
              handleMultipleCardsStatusUpdate(selectedCards, "inactive")
            }
          >
            {`Deactivate Card(s)`}
          </Button>
          <Button
            size={ButtonSize.AppSize}
            variant={ButtonVariant.AppOrange}
            className="ml-4"
            disabled={
              selectedCards.filter((card) => card.status === "inactive")
                .length === 0 || submittingRequest
            }
            onClick={() => {
              const proceed = proceedToHandle("active", selectedCards);
              if (proceed === false) {
                handleMultipleCardsStatusUpdate(selectedCards, "active");
              }
            }}
          >
            {`Activate Card(s)`}
          </Button>
        </div>
        <div className="flex flex-col h-full mt-4 step-3">
          <DataTable
            size="small"
            loading={readAppCardDataLoading}
            stripedRows
            value={deliveredCards}
            pageLinkSize={isMobile() ? 2 : 3}
            paginator
            rows={10}
            dataKey="id"
            style={{
              fontFamily: "Inter",
            }}
            responsiveLayout={isMobile() ? "scroll" : "scroll"}
            filters={cardsFilterConfig}
            sortField="lastFour"
            sortOrder={1}
            paginatorTemplate={newPaginationTemplate}
            emptyMessage="No available records"
            rowClassName={rowClass}
            selectionMode="checkbox"
            selectionPageOnly
            selection={selectedCards}
            onSelectionChange={(e) => {
              e.originalEvent.preventDefault();
              setSelectedCards(e.value);
            }}
            className="!bg-white w-auto min-w-full custom-table !text-brown-500 !border-brown-500"
            rowExpansionTemplate={rowExpansionTemplate}
            expandedRows={expandedRows}
            onRowToggle={(e: DataTableRowToggleParams) =>
              setExpandedRows(e.data as CardShortened[])
            }
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3em" }}
              className="text-sm 5xl:text-base !bg-white"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
            ></Column>
            <Column
              sortable
              filterPlaceholder="Search by last four digits"
              field="lastFour"
              header={isMobile() ? "Card" : "Card Number"}
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={(e) =>
                cardNumberTemplate(
                  e,
                  setShowDeliveryDetails,
                  setDeliveryDetails,
                  isMobile(),
                )
              }
            />
            <Column
              showFilterMatchModes={false}
              filterMenuStyle={{
                width: "14rem",
              }}
              filter={!isMobile()}
              sortable
              field="driverName"
              header="Driver"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              filterMenuClassName="custom-filter"
              filterElement={(e) =>
                driverFilterTemplate(e, currentDriversFilter)
              }
            />

            {!isMobile() && (
              <Column
                showFilterMatchModes={false}
                filterMenuStyle={{ width: "14rem" }}
                filter
                sortable
                header="Vehicle"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                filterMenuClassName="custom-filter"
                field="vehicleName"
                filterElement={(e) =>
                  vehicleFilterTemplate(e, currentVehiclesFilter)
                }
              />
            )}
            {!isMobile() && (
              <Column
                sortable
                field="metaData"
                header="Policy Attached"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={metaDataTemplate}
              />
            )}

            {!isMobile() && (
              <Column
                field="stripeCardProgram"
                header="Card Type"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={stripeCardProgramTemplate}
              />
            )}
            {!isMobile() && (
              <Column
                header="Account Tag"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={accountTagTemplate}
              />
            )}

            {!isMobile() && (
              <Column
                sortable
                field="status"
                header="Card Status"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={statusTemplate}
              />
            )}
            {!isMobile() && (
              <Column
                sortable
                field="smsSecurity"
                header="SMS Security"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={smsSecurityTemplate}
              />
            )}
            {!isMobile() && (
              <Column
                header="Customize"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500 step-4"
                body={(e) => cardsAlreadyDeliveredActionTemplate(e)}
              />
            )}
            {isMobile() && (
              <Column
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                expander
                style={{ width: "3em" }}
              />
            )}
          </DataTable>
        </div>
      </div>
      {showConfirmation && (
        <ConfirmModal
          title={"Confirm activating card(s)"}
          body={
            <div className="font-medium">
              <div className="">
                <div className="mb-3">
                  For this month, you have been invoiced for {variables.var1}{" "}
                  active card(s). You are currently attempting to make{" "}
                  {variables.var2} inactive card(s) active. You will be invoiced
                  for {variables.var3} card(s). Confirm to proceed.
                </div>
              </div>
            </div>
          }
          setShow={setConfirmation}
          show={showConfirmation}
          confirm={confirmCardStatusChange}
          submitting={submittingRequest}
        ></ConfirmModal>
      )}

      {showDeliveryDetails && (
        <InfoModal
          title={"Delivery Details"}
          body={
            <div className="text-sm md:text-base">
              <div className="flex flex-col">
                <div>
                  <span>Delivery Service: </span>
                  <span>
                    {deliveryDetails.deliveryService.slice(0, 1).toUpperCase()}
                    {deliveryDetails.deliveryService.slice(1)}
                  </span>
                </div>
                <div>
                  <span>Delivery Partner: </span>
                  <span>
                    {deliveryDetails.deliveryPartner.slice(0, 1).toUpperCase()}
                    {deliveryDetails.deliveryPartner.slice(1)}
                  </span>
                </div>
                <div>
                  <span>Delivery Status: </span>
                  <span>
                    {deliveryDetails.deliveryStatus.slice(0, 1).toUpperCase()}
                    {deliveryDetails.deliveryStatus.slice(1).toLowerCase()}
                  </span>
                </div>
                <div>
                  <span>Estimated Delivery Date: </span>
                  <span>
                    {" "}
                    <span>
                      {
                        convertISOStringToDate(
                          deliveryDetails.eta.toString(),
                          false,
                          false,
                          true,
                        ).split(",")[0]
                      }
                    </span>
                    <span className="">
                      {
                        convertISOStringToDate(
                          deliveryDetails.eta.toString(),
                          false,
                          false,
                          true,
                        ).split(",")[1]
                      }
                    </span>
                  </span>
                </div>
                <div>
                  <span>Tracking Number: </span>
                  <span>{deliveryDetails.trackingNumber}</span>
                </div>
                <div>
                  <span>Tracking URL: </span>
                  <span>{deliveryDetails.trackingUrl}</span>
                </div>
              </div>
            </div>
          }
          setShow={setShowDeliveryDetails}
          show={showDeliveryDetails}
        ></InfoModal>
      )}

      {openAssignAccountTagModal && (
        <AssignAccountTagModal
          setOpen={setOpenAssignAccountTagModal}
          open={openAssignAccountTagModal}
          accountTagType={ENUM_ACCOUNT_TAG_TYPE.CARD}
          currentAccountTags={selectedAccountTag ? [selectedAccountTag] : []}
          itemName={itemName}
          itemId={itemId}
          refetchData={readAppCardDataRefetch}
        />
      )}

      {openRemovePolicySettingModal && (
        <RemovePolicySettingModal
          title="Remove Policy"
          body={
            <span>Are you sure you want to remove policy from this card?</span>
          }
          show={openRemovePolicySettingModal.open}
          setShow={setOpenRemovePolicySettingModal}
          onSubmit={handleRemoveCardPolicy}
          cardId={openRemovePolicySettingModal?.row?.id as string}
          loading={removeCardPolicyLoading.loading}
        />
      )}
    </div>
  );
};
