import { useMutation, useQuery } from "@apollo/client";
import { ENUM_PAYMENT_METHOD_TYPE } from "@prisma/client";
import {
  READ_BUSINESS_INFORMATION,
  READ_STRIPE_CUSTOMER_PAYMENT_METHODS,
  STRIPE_CHANGE_PAYMENT_METHOD,
  STRIPE_DELETE_PAYMENT_METHOD,
} from "@roadflex/graphql";
import { useState } from "react";
import { Toast } from "../../toast-message/toast";
import { StripePaymentMethodsUIAlt } from "./stripe-payment-methods-alt";
import { StripePaymentMethodsUI } from "./stripe-payment-methods.ui";

type StripePaymentMethodsProps = {
  ui: "box" | "list";
  phrasing: string;
  primaryPaymentMethodType?: ENUM_PAYMENT_METHOD_TYPE;
  selectedPaymentMethod?: string;
  setSelectedPaymentMethod?: (value: string) => void;
};
export const StripePaymentMethods = ({
  ui,
  phrasing,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  primaryPaymentMethodType,
}: StripePaymentMethodsProps) => {
  const [modal, setModal] = useState(false);
  const [changePaymentMethodFn, { loading: changingPaymentMethod }] =
    useMutation(STRIPE_CHANGE_PAYMENT_METHOD, {
      refetchQueries: [
        READ_STRIPE_CUSTOMER_PAYMENT_METHODS,
        READ_BUSINESS_INFORMATION,
      ],
    });
  const [deletePaymentMethodFn, { loading: deletingPaymentMethod }] =
    useMutation(STRIPE_DELETE_PAYMENT_METHOD, {
      refetchQueries: [READ_STRIPE_CUSTOMER_PAYMENT_METHODS],
    });
  const {
    data: customerPaymentMethods,
    loading: customerPaymentMethodsLoading,
  } = useQuery(READ_STRIPE_CUSTOMER_PAYMENT_METHODS);

  const changePaymentMethod = async (paymentMethodId: string) => {
    try {
      await changePaymentMethodFn({
        variables: {
          data: {
            paymentMethodId,
          },
        },
      });
      Toast({
        type: "success",
        message: "Default payment method changed to card",
      });
      setModal(false);
    } catch (err) {
      if (err instanceof Error) {
        Toast({ type: "error", message: err.message });
      } else {
        Toast({ type: "error", message: "Something went wrong" });
      }
    }
  };
  const deletePaymentMethod = async (paymentMethodId: string) => {
    try {
      await deletePaymentMethodFn({
        variables: {
          data: {
            paymentMethodId,
          },
        },
      });
      Toast({ type: "success", message: "Card deleted successfully" });
      setModal(false);
    } catch (err) {
      if (err instanceof Error) {
        Toast({ type: "error", message: err.message });
      } else {
        Toast({ type: "error", message: "Something went wrong" });
      }
    }
  };
  return ui === "box" && primaryPaymentMethodType ? (
    <StripePaymentMethodsUI
      changePaymentMethod={changePaymentMethod}
      deletePaymentMethod={deletePaymentMethod}
      deletingPaymentMethod={deletingPaymentMethod}
      changingPaymentMethod={changingPaymentMethod}
      customerPaymentMethodsLoading={customerPaymentMethodsLoading}
      customerPaymentMethods={
        customerPaymentMethods?.readStripeCustomerPaymentMethods
          ?.paymentMethods || []
      }
      primaryPaymentMethodType={primaryPaymentMethodType}
      modal={modal}
      setModal={setModal}
      phrasing={phrasing}
    ></StripePaymentMethodsUI>
  ) : setSelectedPaymentMethod ? (
    <StripePaymentMethodsUIAlt
      customerPaymentMethodsLoading={customerPaymentMethodsLoading}
      customerPaymentMethods={
        customerPaymentMethods?.readStripeCustomerPaymentMethods
          ?.paymentMethods || []
      }
      selectedPaymentMethod={selectedPaymentMethod || ""}
      setSelectedPaymentMethod={setSelectedPaymentMethod}
      phrasing={phrasing}
    ></StripePaymentMethodsUIAlt>
  ) : null;
};
