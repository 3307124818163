import { ENUM_ACCOUNT_TAG_TYPE } from "@prisma/client";
import { CustomMultiSelect, Loader } from "@roadflex/react-components-www";
import {
  useAssignAccountTag,
  usePlatformDetect,
  useReadAccountTag,
} from "@roadflex/react-hooks";
import { AccountTagType } from "@roadflex/types";
import { capitalizeFirstLetter } from "@roadflex/utils";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { Toast } from "../../toast-message/toast";

const ACCOUNT_TAGS_COLOR = [
  "bg-slate-200",
  "bg-red-200",
  "bg-amber-200",
  "bg-green-200",
  "bg-teal-200",
  "bg-gray-200",
  "bg-purple-200",
  "bg-blue-200",
  "bg-lime-200",
  "bg-orange-200",
  "bg-sky-200",
  "bg-pink-200",
];

const accountTagItemTemplate = (accountTagItem: AccountTagType) => {
  const index = Number(accountTagItem?.colorCode) || 0;
  const length = ACCOUNT_TAGS_COLOR.length;
  const colorCode = ACCOUNT_TAGS_COLOR[index % length];
  return (
    <div>
      <span
        className={classNames(colorCode, "rounded-full text-sm px-2 mr-3")}
      ></span>
      <span>
        {accountTagItem.title}{" "}
        {accountTagItem.tagId ? <span>({accountTagItem.tagId})</span> : null}
      </span>
    </div>
  );
};

type AssignAccountTagModalProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  accountTagType: ENUM_ACCOUNT_TAG_TYPE;
  currentAccountTags: AccountTagType[];
  itemName: string;
  itemId: string;
  refetchData: () => void;
};

export const AssignAccountTagModal = ({
  open,
  setOpen,
  accountTagType,
  currentAccountTags,
  itemName,
  itemId,
  refetchData,
}: AssignAccountTagModalProps) => {
  const { data, loading } = useReadAccountTag({
    tagType: accountTagType,
  });

  const { resetForm, setFieldValue, handleSubmit, isSubmitting } =
    useAssignAccountTag({
      initialValues: {
        id: "",
        accountTagIds: [],
        tagType: accountTagType,
      },
      onSubmit(res, err) {
        if (err) {
          Toast({ type: "error", message: err.message });
          return;
        }
        if (res?.code === "200") {
          Toast({
            type: "success",
            message: res?.message,
          });
        }
        resetForm();
        setOpen(false);
        refetchData();
      },
    });

  const cancelButtonRef = useRef(null);
  const [accountTags, setAccountTags] = useState<AccountTagType[]>([]);
  const [selectedAccountTags, setSelectedAccountTags] = useState<string[]>([]);
  const { isMobile } = usePlatformDetect();

  const footer = (
    <div className="flex flex-row justify-end">
      <Button
        type={ButtonType.Button}
        variant={ButtonVariant.Red}
        size={ButtonSize.AppSize}
        disabled={isSubmitting}
        className="w-full"
        onClick={() => setOpen(false)}
        ref={cancelButtonRef}
      >
        Cancel
      </Button>
      <Button
        variant={ButtonVariant.Black}
        size={ButtonSize.AppSize}
        disabled={isSubmitting || loading}
        loading={isSubmitting}
        className="w-full ml-3"
        onClick={() => {
          setFieldValue("id", itemId);
          setFieldValue("accountTagIds", selectedAccountTags);
          handleSubmit();
        }}
      >
        Save
      </Button>
    </div>
  );

  const renderHeader = () => {
    return (
      <div className="text-base">{`${
        selectedAccountTags.length ? "Edit" : "Add"
      } tags for ${capitalizeFirstLetter(accountTagType)} - ${itemName}`}</div>
    );
  };

  const populateAccountTagData = (value: ENUM_ACCOUNT_TAG_TYPE) => {
    const accountTagList = data?.readAccountTags?.accountTagList || {};
    let activeTags: AccountTagType[];
    switch (value) {
      case ENUM_ACCOUNT_TAG_TYPE.CARD:
        activeTags = accountTagList?.CARD?.active || [];
        break;
      case ENUM_ACCOUNT_TAG_TYPE.DRIVER:
        activeTags = accountTagList?.DRIVER?.active || [];
        break;
      case ENUM_ACCOUNT_TAG_TYPE.VEHICLE:
        activeTags = accountTagList?.VEHICLE?.active || [];
        break;
      case ENUM_ACCOUNT_TAG_TYPE.TRANSACTION:
        activeTags = accountTagList?.TRANSACTION?.active || [];
        break;
      default:
        activeTags = [];
        break;
    }
    setAccountTags(activeTags);
    setSelectedAccountTags(
      currentAccountTags
        ? currentAccountTags.map((accTagObj) => accTagObj?.id || "")
        : [],
    );
  };

  useEffect(() => {
    if (data && data.readAccountTags && data.readAccountTags.accountTagList) {
      populateAccountTagData(accountTagType);
    }
  }, [data]);

  return (
    <Dialog
      header={renderHeader}
      visible={open}
      style={{
        minWidth: isMobile() ? "90vw" : "40vw",
        maxWidth: isMobile() ? "90vw" : "80vw",
        fontFamily: "Inter",
      }}
      footer={footer}
      onHide={() => setOpen(false)}
      closeOnEscape={true}
      closable={false}
    >
      <div className="w-full">
        {loading && <Loader />}
        <CustomMultiSelect
          id="multi-select-account-tags"
          value={selectedAccountTags}
          options={accountTags}
          placeholder="Select Account Tags"
          disabled={loading}
          maxSelectedLabels={4}
          className="w-full !text-sm h-10 flex items-center"
          onChange={(e) => {
            setSelectedAccountTags(e.value);
          }}
          itemTemplate={accountTagItemTemplate}
          optionLabel="title"
          optionValue="id"
          display="chip"
          selectionLimit={
            accountTagType === ENUM_ACCOUNT_TAG_TYPE.CARD ? 1 : undefined
          }
        />
      </div>
    </Dialog>
  );
};
