/** @format */

import { useQuery } from "@apollo/client";
import { ENUM_ELD_ORG_NAME } from "@prisma/client";
import { ELD_CONNECTED } from "@roadflex/graphql";
import { Toast } from "@roadflex/react-components-www";
import { useGetEldDevices, useReadVehicles } from "@roadflex/react-hooks";
import {
  EldDevices,
  MapType,
  TripDetails,
  UserAuthType,
} from "@roadflex/types";
import { useEffect, useMemo, useState } from "react";
import { Loader } from "../../loader";
import Redirect from "../../redirect/redirect";
import { EldDetailsPageUI } from "./eld-system-detail-page.ui";

interface EldDetailsProps {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
  name: string;
  refetch: () => void;
}

export const EldDetailsPage = ({
  readCurrentUserAuth,
  loading,
  name,
  refetch,
}: EldDetailsProps) => {
  const [devices, setDevices] = useState<Record<string, EldDevices[]>>({});
  const [openGeoTabDeviceTripsModal, setOpenGeoTabDeviceTripsModal] =
    useState(false);
  const [openGMapsModal, setOpenGMapsModal] = useState(false);
  const [openEditGeoTabDeviceModal, setOpenEditGeoTabDeviceModal] =
    useState(false);
  const [isInitLoad, setInitLoad] = useState(true);

  const { getVehiclesData, getVehiclesLoading } = useReadVehicles([]);

  // Constants for map and trip details
  const initialMapValuesObj = {
    currentStateDuration: "",
    deviceId: "",
    deviceName: "",
    driver: "",
    isDriving: "",
    isHistoricLastDriver: "",
    dateTime: "",
  };

  const todayDateObj = new Date();
  todayDateObj.setHours(0, 0, 0, 0);
  const oneWeekAgoDateObj = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);
  oneWeekAgoDateObj.setHours(0, 0, 0, 0);

  const initialTripDetailsObj = {
    dates: [oneWeekAgoDateObj, todayDateObj],
    fromVersion: "0000000000000000",
    resultsLimit: 500,
    deviceId: "",
    deviceName: "",
  };

  const [mapValues, setMapValues] = useState<MapType>(initialMapValuesObj);
  const [tripDetailValues, setTripDetailValues] = useState<TripDetails>(
    initialTripDetailsObj,
  );
  const [database, setDatabase] = useState("");
  const [orgName, setOrgName] = useState(name?.toUpperCase() || "");
  const [editGeoTabDeviceValues, setEditGeoTabDeviceValues] = useState({
    geoTabDeviceId: "",
    geoTabDeviceName: "",
    vehicleId: "",
  });

  const { data: eldConnected } = useQuery<{
    isEldConnected: {
      isConnected: boolean;
      orgName: ENUM_ELD_ORG_NAME;
    };
  }>(ELD_CONNECTED, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (
      (eldConnected && !eldConnected.isEldConnected.isConnected) ||
      (eldConnected && orgName !== eldConnected.isEldConnected.orgName)
    ) {
      window.location.href = "/eld-systems";
    }
  }, [eldConnected, orgName]);

  const currentDevices = useMemo(() => {
    return devices[orgName] || [];
  }, [devices, orgName]);

  const { handleSubmit: eldDevicesHandleSubmit, ...eldGetDevicesFormikValues } =
    useGetEldDevices({
      initialValues: {
        database: "",
        orgName: orgName,
      },
      onSubmit(res, err) {
        if (err) {
          Toast({ type: "error", message: err?.message });
          return;
        }

        if (res?.getEldDevices?.code === "200") {
          // Toast({ type: "success", message: res?.getEldDevices?.message });
          const eldDevices = res?.getEldDevices?.eldDevices || [];

          setDevices((prev) => ({
            ...prev,
            [orgName]: eldDevices,
          }));

          if (isInitLoad) {
            setInitLoad(false);
          }
        } else {
          Toast({
            type: "error",
            message: res?.getEldDevices?.message || "Something went wrong",
          });
        }
      },
    });

  const setMapData = (rowData: EldDevices) => {
    setMapValues({
      ...initialMapValuesObj,
      deviceId: rowData?.id,
      deviceName: rowData?.deviceName,
    });
    setOpenGMapsModal(true);
  };

  const openGeoTabTripDetailsModal = (rowData: EldDevices) => {
    setTripDetailValues({
      ...initialTripDetailsObj,
      deviceId: rowData.id,
      deviceName: rowData?.deviceName,
    });
    setOpenGeoTabDeviceTripsModal(true);
  };

  const openEditGeoTabDetailsModal = (rowData: EldDevices) => {
    setEditGeoTabDeviceValues({
      geoTabDeviceId: rowData.eldDeviceDetailsId,
      geoTabDeviceName: rowData.id,
      vehicleId: rowData?.fleetId || "",
    });
    setOpenEditGeoTabDeviceModal(true);
  };

  useEffect(() => {
    eldDevicesHandleSubmit();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const vehiclesList = getVehiclesData?.readVehicles?.vehicles || [];

  if (!Object.keys(ENUM_ELD_ORG_NAME).find((value) => value === orgName)) {
    return <Redirect path="/eld-systems" />;
  }

  return (
    <EldDetailsPageUI
      devices={currentDevices}
      openGeoTabDeviceTripsModal={openGeoTabDeviceTripsModal}
      setOpenGeoTabDeviceTripsModal={setOpenGeoTabDeviceTripsModal}
      openGeoTabTripDetailsModal={openGeoTabTripDetailsModal}
      openGMapsModal={openGMapsModal}
      setOpenGMapsModal={setOpenGMapsModal}
      setMapData={setMapData}
      mapValues={mapValues}
      setMapValues={setMapValues}
      tripDetailValues={tripDetailValues}
      orgName={orgName}
      setOrgName={setOrgName}
      database={database}
      openEditGeoTabDeviceModal={openEditGeoTabDeviceModal}
      setOpenEditGeoTabDeviceModal={setOpenEditGeoTabDeviceModal}
      openEditGeoTabDetailsModal={openEditGeoTabDetailsModal}
      editGeoTabDeviceValues={editGeoTabDeviceValues}
      setEditGeoTabDeviceValues={setEditGeoTabDeviceValues}
      vehiclesList={vehiclesList}
      getVehiclesLoading={getVehiclesLoading}
      geoTabGetDevicesForm={{
        handleSubmit: eldDevicesHandleSubmit,
        ...eldGetDevicesFormikValues,
      }}
    />
  );
};
