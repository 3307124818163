import {
  ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_FUEL_CATEGORIES,
  ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_NON_FUEL_CATEGORIES,
} from "@prisma/client";
import { Loader, Toast } from "@roadflex/react-components-www";
import {
  useReadDynamicPurchaseControls,
  useUpdateDynamicPurchaseControls,
} from "@roadflex/react-hooks";
import { useState } from "react";
import { DynamicPurchaseControlsUI } from "./dynamic-purchase-controls.ui";

export type DynamicPurchaseControlsProps = {
  object: "card" | "driver" | "vehicle" | "policy";
  id: string;
  access: boolean;
  enableEdit: boolean;
  createMode: boolean;
  createNewFunction?: (value: {
    createNew: boolean;
    additionalValues?: {
      dynamicPurchaseControlsSetting: boolean;
      dynamicPurchaseControlsAllowedFuelCategories: ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_FUEL_CATEGORIES[];
      dynamicPurchaseControlsAllowedNonFuelCategories: ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_NON_FUEL_CATEGORIES[];
    };
  }) => void;
};

export const DynamicPurchaseControls = ({
  object,
  id,
  access,
  enableEdit,
  createMode,
  createNewFunction,
}: DynamicPurchaseControlsProps) => {
  const [editDynamicPurchaseControls, setEditDynamicPurchaseControls] =
    useState(false);
  const {
    readDynamicPurchaseControlsData,
    readDynamicPurchaseControlsRefetchLoading,
  } = useReadDynamicPurchaseControls(object, id);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    resetForm,
    validateForm,
    setFieldTouched,
    setValues,
    ...rest
  } = useUpdateDynamicPurchaseControls({
    initialValues: {
      id,
      dynamicPurchaseControlsSetting:
        readDynamicPurchaseControlsData?.readDynamicPurchaseControls
          ?.dynamicPurchaseControlsSetting || false,
      dynamicPurchaseControlsAllowedFuelCategories:
        readDynamicPurchaseControlsData?.readDynamicPurchaseControls
          ?.dynamicPurchaseControlsAllowedFuelCategories || [],
      dynamicPurchaseControlsAllowedNonFuelCategories:
        readDynamicPurchaseControlsData?.readDynamicPurchaseControls
          ?.dynamicPurchaseControlsAllowedNonFuelCategories || [],
      object,
    },
    onSubmit: async (res, err) => {
      if (err) {
        Toast({ type: "error", message: err?.message });
        setEditDynamicPurchaseControls(false);
        return;
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res?.message });
      }
      if (res?.code !== "200" && res?.message) {
        Toast({ type: "error", message: res?.message });
      }
      setEditDynamicPurchaseControls(false);
    },
  });

  const createNewDynamicPurchaseControls = async () => {
    const validationErrors = await validateForm();

    if (Object.keys(validationErrors).length > 0) {
      // Display the first error in a toast
      const firstErrorMessage = Object.values(validationErrors)[0];
      Toast({
        type: "error",
        message: firstErrorMessage as string,
      });
      return;
    }
    if (createNewFunction) {
      createNewFunction({
        createNew: true,
        additionalValues: {
          dynamicPurchaseControlsAllowedFuelCategories:
            values.dynamicPurchaseControlsAllowedFuelCategories,
          dynamicPurchaseControlsAllowedNonFuelCategories:
            values.dynamicPurchaseControlsAllowedNonFuelCategories,
          dynamicPurchaseControlsSetting: values.dynamicPurchaseControlsSetting,
        },
      });
    }
  };

  if (readDynamicPurchaseControlsRefetchLoading) {
    <Loader></Loader>;
  }
  return (
    <DynamicPurchaseControlsUI
      {...{
        editDynamicPurchaseControls,
        setEditDynamicPurchaseControls,
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        touched,
        errors,
        setFieldValue,
        resetForm,
        validateForm,
        setFieldTouched,
        setValues,
        access,
        enableEdit,
        createMode,
        createNewDynamicPurchaseControls,
        ...rest,
      }}
    />
  );
};
