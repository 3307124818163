/** @format */
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_PREPAID_ELIGIBLE,
  MOVE_CREDIT_TO_PREPAID,
} from "@roadflex/graphql";

export const useReadPrepaidEligible = () => {
  const { data, refetch, ...rest } = useQuery(GET_PREPAID_ELIGIBLE, {
    fetchPolicy: "no-cache",
  });
  return { data, refetch, ...rest };
};

export const useCreditToPrepaid = () => {
  const [onCreditToPrepaidFn, { data, loading, error }] = useMutation(
    MOVE_CREDIT_TO_PREPAID,
    {
      refetchQueries: [GET_PREPAID_ELIGIBLE],
      fetchPolicy: "no-cache",
    },
  );
  return { onCreditToPrepaidFn, loading, error };
};
