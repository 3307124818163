// type AccountControlsProps = {
// };

import {
  ENUM_CARD_SHARING,
  ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING,
} from "@prisma/client";
import { Toast } from "@roadflex/react-components-www";
import { useUpdateAccountControlSetting } from "@roadflex/react-hooks";
import { FeatureType } from "@roadflex/types";
import { useEffect } from "react";
import AccountControlsUI from "./account-controls.ui";

type AccountControlProps = {
  dynamicPurchaseControlsLinkedTo: ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING;
  cardSharingControls: ENUM_CARD_SHARING;
  controlCenterFeatureAccess: FeatureType[];
  accountControlSettingRefetch: () => void;
};
const AccountControls = (props: AccountControlProps) => {
  const {
    dynamicPurchaseControlsLinkedTo,
    cardSharingControls,
    controlCenterFeatureAccess,
    accountControlSettingRefetch,
  } = props;
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    handleReset,
    setFieldValue,
    ...rest
  } = useUpdateAccountControlSetting({
    initialValues: {
      dynamicPurchaseControlsLinkedTo: dynamicPurchaseControlsLinkedTo,
      cardSharingControls: cardSharingControls,
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err?.message });
        return;
      }
      if (res?.code === "200") {
        accountControlSettingRefetch();
        Toast({ type: "success", message: res?.message });
      }
      if (res?.code !== "200" && res?.message) {
        Toast({ type: "error", message: res?.message });
      }
    },
  });
  useEffect(() => {
    setFieldValue(
      "dynamicPurchaseControlsLinkedTo",
      dynamicPurchaseControlsLinkedTo,
    );
    setFieldValue("cardSharingControls", cardSharingControls);
  }, [dynamicPurchaseControlsLinkedTo, cardSharingControls]);
  return (
    <AccountControlsUI
      {...{
        values,
        handleChange,
        handleSubmit,
        handleReset,
        isSubmitting,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        controlCenterFeatureAccess,
        ...rest,
      }}
    />
  );
};
export default AccountControls;
