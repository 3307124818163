/** @format */
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { usePlatformDetect } from "@roadflex/react-hooks";
import {
  ChildUserResendEmailType,
  ChildUserType,
  IdInputType,
  UserAuthType,
} from "@roadflex/types";
import { humanizeEnum } from "@roadflex/utils";
import { FormikErrors, FormikProps } from "formik";
import router from "next/router";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Menu } from "primereact/menu";
import { createRef, useRef } from "react";
import { Toast } from "../../../src/toast-message/toast";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";

type UsersUIProps = FormikProps<IdInputType> & {
  accountUsersLoading: boolean;
  usersList: ChildUserType[];
  resendInvitationSubmit: () => void;
  resendInvitationSubmitting: boolean;
  resendInvitationSetValues: (
    fields: React.SetStateAction<ChildUserResendEmailType>,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<unknown>>;
  readCurrentUserAuth: UserAuthType;
};
const UsersUI = ({
  accountUsersLoading,
  usersList,
  setValues,
  handleSubmit,
  resendInvitationSubmit,
  resendInvitationSubmitting,
  resendInvitationSetValues,
  readCurrentUserAuth,
}: UsersUIProps) => {
  const { isMobile } = usePlatformDetect();
  const UsersRef = useRef<(React.RefObject<Menu> | null)[]>([]);
  UsersRef.current = usersList.map(
    (_, i) => UsersRef.current[i] ?? createRef(),
  );

  const emailVerifiedTemplate = (rowData: ChildUserType) => {
    return (
      <div className="flex flex-row items-center justify-start">
        <div>{rowData.status?.replace("_", " ")}</div>
        {rowData?.status === "INVITATION_SENT" && (
          <div className="pl-2">
            <Button
              variant={ButtonVariant.Transparent}
              size={ButtonSize.Small}
              className="text-xs italic"
              disabled={resendInvitationSubmitting}
              onClick={() => {
                resendInvitationSetValues({ id: rowData.id || "" });
                resendInvitationSubmit();
              }}
            >
              Resend Invitation
            </Button>
          </div>
        )}
      </div>
    );
  };

  const roleNameTemplate = (rowData: ChildUserType) => {
    return (
      <div className="flex flex-row items-center justify-start">
        {rowData.roleName !== "BOOKKEEPER" && (
          <div>{humanizeEnum(rowData.roleName)}</div>
        )}
        {rowData.roleName === "BOOKKEEPER" && <div>Book Keeper</div>}
      </div>
    );
  };

  return (
    <div className="m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        {`Users and Permissions`.toUpperCase()}
      </div>
      <div className="p-4 mt-5 overflow-hidden text-sm font-medium bg-white rounded-md shadow md:p-8 md:mt-0 md:text-base">
        <div className="flex flex-row justify-end w-full mb-4">
          <Button
            onClick={() => {
              router.push("/multiple-users/new");
            }}
            size={ButtonSize.AppSize}
            variant={ButtonVariant.AppOrange}
            className="flex flex-row items-center text-sm md:text-base"
          >
            Add User
          </Button>
        </div>
        <div className="flex items-center ">
          <div className="w-full">
            <div className="overflow-hidden">
              <DataTable
                pageLinkSize={3}
                size="small"
                stripedRows
                loading={accountUsersLoading}
                value={usersList}
                paginator
                rows={10}
                dataKey="id"
                sortField="createdAt"
                sortOrder={1}
                paginatorTemplate={newPaginationTemplate}
                selectionMode="checkbox"
                responsiveLayout={isMobile() ? "scroll" : "scroll"}
                emptyMessage="No available records"
                style={{ fontFamily: "Inter" }}
                className="custom-table"
              >
                {!isMobile() && (
                  <Column
                    // filter
                    sortable
                    field="email"
                    header="Email"
                    className="text-sm 5xl:text-base"
                    bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                    headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  />
                )}
                {!isMobile() && (
                  <Column
                    // filter
                    field="accountStatus"
                    header="Account Status"
                    className="text-sm 5xl:text-base"
                    bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                    headerClassName="!bg-white !text-brown-500 !border-brown-500"
                    body={emailVerifiedTemplate}
                  />
                )}
                <Column
                  // filter
                  sortable={!isMobile()}
                  filterPlaceholder="Search by Name"
                  field="firstName"
                  header="First Name"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
                <Column
                  // filter
                  sortable={!isMobile()}
                  filterPlaceholder="Search by Name"
                  field="lastName"
                  header="Last Name"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
                {!isMobile() && (
                  <Column
                    // filter
                    sortable
                    sortField="phoneNumber"
                    field="phoneNumber"
                    header="Phone"
                    className="text-sm 5xl:text-base"
                    bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                    headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  />
                )}
                <Column
                  sortable={!isMobile()}
                  field="roleName"
                  header="Role"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={roleNameTemplate}
                />
                <Column
                  headerStyle={{ width: "3em" }}
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(e: ChildUserType, props) => (
                    <div className="relative flex">
                      <Menu
                        model={[
                          {
                            label: "Edit",
                            icon: "pi pi-fw pi-pencil",
                            command: () => {
                              if (e.status === "INVITATION_SENT") {
                                Toast({
                                  type: "info",
                                  message:
                                    "You can edit once the user has created the account",
                                });
                                return;
                              }
                              if (e.owner) {
                                Toast({
                                  type: "info",
                                  message: "You cannot edit the account owner",
                                });
                                return;
                              }
                              router.push(`/multiple-users/${e.id}`);
                            },
                          },
                          {
                            label: "Delete",
                            icon: "pi pi-fw pi-trash",
                            command: () => {
                              if (e.owner) {
                                Toast({
                                  type: "info",
                                  message:
                                    "You cannot delete the account owner",
                                });
                                return;
                              }
                              setValues({ id: e.id || "" });
                              handleSubmit();
                            },
                          },
                        ]}
                        popup
                        ref={UsersRef.current[props.rowIndex]}
                      />
                      <Button
                        variant={ButtonVariant.Transparent}
                        className="!p-0"
                        onClick={(event) =>
                          UsersRef.current[props.rowIndex]?.current?.toggle(
                            event,
                          )
                        }
                      >
                        <EllipsisVerticalIcon className="w-5"></EllipsisVerticalIcon>
                      </Button>
                    </div>
                  )}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersUI;
