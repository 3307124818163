/** @format */

import { useQuery } from "@apollo/client";
import { ENUM_ACCOUNT_TAG_TYPE } from "@prisma/client";
import { READ_SINGLE_ACCOUNT_TAG } from "@roadflex/graphql";
import { useUpdateAccountTag } from "@roadflex/react-hooks";
import { UserAuthType } from "@roadflex/types";
import { useRouter } from "next/router";
import { useState } from "react";
import { Toast } from "../../../../src/toast-message/toast";
import { AccountTagsUI } from "./account-tag.ui";

type AccountTagsProps = {
  readCurrentUserAuth: UserAuthType;
  tagId: string;
};

export const AccountTagsEdit = ({
  readCurrentUserAuth,
  tagId,
}: AccountTagsProps) => {
  const tabs = [{ id: 1, name: "Account Tag", current: true }];
  const [update, setUpdate] = useState(false);
  const router = useRouter();

  const { data: accountTagData } = useQuery<{
    readSingleAccountTag: {
      code: string;
      message: string;
      totalCount: number;
      accountTagList: {
        id: string;
        title: string;
        description: string;
        tagId: string;
        tagType: string;
        deleted: boolean;
        createdAt: Date;
        updatedAt: Date;
        promptCode: string;
      }[];
    };
  }>(READ_SINGLE_ACCOUNT_TAG, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      data: { id: tagId },
    },
  });
  const {
    resetForm: createAccountTagResetForm,
    setValues,
    ...accountTagFormikValues
  } = useUpdateAccountTag({
    initialValues: {
      title:
        accountTagData?.readSingleAccountTag?.accountTagList[0]?.title || "",
      description:
        accountTagData?.readSingleAccountTag?.accountTagList[0]?.description ||
        "",
      tagType:
        (accountTagData?.readSingleAccountTag?.accountTagList[0]
          ?.tagType as ENUM_ACCOUNT_TAG_TYPE) || ENUM_ACCOUNT_TAG_TYPE.CARD,
      id: tagId,
      promptCode:
        accountTagData?.readSingleAccountTag?.accountTagList[0]?.promptCode ||
        "",
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res.message });
      } else {
        Toast({ type: "error", message: res?.message || "" });
      }
      createAccountTagResetForm();
      router.push("/account-tags");
    },
  });

  // useEffect(() => {
  //   if (accountTagData) {
  //     setValues({
  //       title: accountTagData?.readSingleAccountTag?.accountTagList[0]?.title,
  //       description:
  //         accountTagData?.readSingleAccountTag?.accountTagList[0]?.description,
  //       id: tagId,
  //       tagType: accountTagData?.readSingleAccountTag?.accountTagList[0]
  //         ?.tagType as ENUM_ACCOUNT_TAG_TYPE,
  //       promptCode:
  //         accountTagData?.readSingleAccountTag?.accountTagList[0]?.promptCode ||
  //         "",
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [accountTagData]);

  return (
    <AccountTagsUI
      {...{
        tabs,
        tagId,
        resetForm: createAccountTagResetForm,
        update,
        setUpdate,
        ...accountTagFormikValues,
        setValues,
      }}
    />
  );
};
