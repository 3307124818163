/* eslint-disable dot-notation */
/** @format */
import { useMutation } from "@apollo/client";
import { TELEMATICS_GET_DEVICES } from "@roadflex/graphql";
import { EldDevices, GeoTabGetDevicesValues } from "@roadflex/types";
import { useFormik } from "formik";

type EldDevicesResponse = {
  getEldDevices: {
    code: string;
    message: string;
    eldDevices: EldDevices[];
  } | null;
};

type useGetEldDevicesType = {
  initialValues: GeoTabGetDevicesValues;
  onSubmit: (response: EldDevicesResponse | null, error: Error | null) => void;
};

export const useGetEldDevices = ({
  onSubmit,
  initialValues,
}: useGetEldDevicesType) => {
  const [fetchEldDevices] = useMutation<{
    getEldDevices: {
      code: string;
      message: string;
      eldDevices: EldDevices[];
    };
  }>(TELEMATICS_GET_DEVICES, { fetchPolicy: "no-cache" });

  const {
    handleChange,
    handleSubmit,
    values,
    isSubmitting,
    setFieldValue,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      const data = {
        ...value,
      };
      try {
        const res = await fetchEldDevices({
          variables: { data },
        });

        onSubmit({ getEldDevices: res?.data?.getEldDevices || null }, null);
      } catch (error) {
        console.error("Error fetching ELD devices:", error);
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(
            null,
            new Error("Something went wrong while fetching ELD devices"),
          );
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    ...rest,
  };
};
