/** @format */
import { LifebuoyIcon } from "@heroicons/react/24/outline";
import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import {
  BillingCycleType,
  DashboardDataType,
  UserAuthType,
} from "@roadflex/types";
import { convertISOStringToDateNew, humanizeEnum } from "@roadflex/utils";
import {
  calculateDueDate,
  dollarFormatter,
  getCookie,
  setCookie,
  valueIsNullOrUndefined,
} from "@roadflex/web-lib";
import { differenceInDays, isWithinInterval, parseISO } from "date-fns";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";
import ExportImportModalLoader from "../../loader/export-import-modal-loader";
import { StatementsDownloadModal } from "../../modals";
import { Toast } from "../../toast-message/toast";
import { StatementMultipleDownloads } from "./statement-multiple-download";

interface StatementsUIProps {
  readAppDashboardData?: DashboardDataType;
  readAppDashboardDataLoading: boolean;
  readCurrentUserAuth: UserAuthType;
  billingList: BillingCycleType[];
  billingCycleListLoading: boolean;
  billingPeriodTemplate: (
    rowData: BillingCycleType,
    tz: string,
  ) => React.ReactNode;
  amountTemplate: (rowData: BillingCycleType, sign: string) => React.ReactNode;
  statusTemplate: (rowData: BillingCycleType) => React.ReactNode;
  modal: boolean;
  selected?: BillingCycleType | null;
  toggleModal: () => void;
  amountOweTemplate: (rowData: BillingCycleType) => React.ReactNode;
  joyride: boolean;
  setJoyride: (value: boolean) => void;
  steps: Step[];
  totalCount: number;
  onPageAndSortBillingCycle: (e: DataTablePFSEvent) => void;
  billingCycleLazyParams: DataTablePFSEvent;
}
const StatementsUI = ({
  readAppDashboardData,
  readAppDashboardDataLoading,
  readCurrentUserAuth,
  billingList,
  totalCount,
  billingCycleLazyParams,
  onPageAndSortBillingCycle,
  billingCycleListLoading,
  billingPeriodTemplate,
  amountTemplate,
  statusTemplate,
  modal,
  selected,
  toggleModal,
  amountOweTemplate,
  joyride,
  setJoyride,
  steps,
}: StatementsUIProps) => {
  const customerType = readCurrentUserAuth?.fuelCard?.customerType;

  const [dates, setDates] = useState<Date | Date[] | undefined>([
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    new Date(),
  ]);
  const [multipleDownloadData, setMultipleDownloadData] = useState<string[]>(
    [],
  );
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [showComplete, setShowComplete] = useState<boolean>(false);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const DueDateComponent = () => {
    if (readAppDashboardDataLoading) {
      return <></>;
    }
    if (
      !valueIsNullOrUndefined(
        readAppDashboardData?.outstandingAmount?.currentStatementDue,
      ) &&
      readAppDashboardData?.latestCycle?.endDt &&
      readCurrentUserAuth.fuelCard.creditCycleType
    ) {
      return <></>;
    }
    return (
      <div className="absolute bottom-2 right-2">
        <div className="text-sm text-gray-500 max-w-[250px]">
          <span className="text-right"></span>Statement not generated yet.
        </div>
      </div>
    );
  };

  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setJoyride(true);
  };
  useEffect(() => {
    const statementGuide = getCookie("statementGuide");
    if (!statementGuide) {
      setJoyride(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleJoyrideCallback = (callbackData: CallBackProps) => {
    const { status, type } = callbackData;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setJoyride(false);
    }
    if (status === STATUS.READY) {
      setCookie("statementGuide", "true", 30);
    }
  };

  const dateFilterApplied = (e: unknown) => {
    const event = e as {
      field: string;
      constraints: {
        operator: FilterOperator;
        constraints: [
          { value: null | string | Date; matchMode: FilterMatchMode },
        ];
      };
    };
  };
  const onDateRangeChange = (e: CalendarChangeParams) => {
    const dateArray = e.value as Date[];
    if (differenceInDays(dateArray[1], dateArray[0]) > 30) {
      setDates([]);
      Toast({ type: "error", message: "Maximum range is 30 days" });
    } else {
      setDates(dateArray);
      if (dateArray[0] !== null && dateArray[1] !== null) {
        dateFilterApplied({
          field: "date",
          constraints: {
            operator: "and",
            constraints: [
              {
                value: new Date(dateArray[0]),
                matchMode: "dateAfter",
              },
              {
                value: new Date(dateArray[1]),
                matchMode: "dateBefore",
              },
            ],
          },
        });
      }
    }
  };
  const minDate = new Date();
  minDate.setMonth(0); //means January, because 0-indexed
  minDate.setFullYear(2022);
  minDate.setDate(1);

  const onMultipleStatementDownload = () => {
    // const diffInMinutes = getUtcToPstDifferenceInMinutes();s

    const statementids = billingList
      .filter((billingCycle) => {
        return (
          isWithinInterval(
            parseISO(new Date(billingCycle.startingDate).toISOString()),
            {
              start: parseISO(new Date((dates as Date[])[0]).toISOString()),
              end: parseISO(new Date((dates as Date[])[1]).toISOString()),
            },
          ) &&
          +billingCycle.spendings -
            +billingCycle.rewards +
            +billingCycle.lateFees >
            0
        );
      })
      .map((billingCycle) => {
        return billingCycle.billingCycleId;
      });
    if (statementids.length > 0) {
      setMultipleDownloadData(statementids);
    } else {
      Toast({ type: "info", message: "No spending in the given date range" });
    }
  };

  let tz = "";
  if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "EST") {
    tz = "America/New_York";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "PST") {
    tz = "America/Los_Angeles";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "UTC") {
    tz = "UTC";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "CT") {
    tz = "America/Chicago";
  }
  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="flex justify-between text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        <div className="">{`Statement/Invoice`.toUpperCase()}</div>
        <div className="flex items-center justify-end rounded-md md:mt-0 step-6">
          <div>
            <Button
              onClick={handleClickStart}
              variant={ButtonVariant.Transparent}
              size={ButtonSize.Wrap}
            >
              <LifebuoyIcon
                className="flex-shrink-0 w-4 h-4"
                aria-hidden="true"
              />
              <span className="px-1 text-xs italic">Help Center</span>
            </Button>
          </div>
        </div>
      </div>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={joyride}
        scrollToFirstStep
        showProgress
        scrollOffset={76}
        disableCloseOnEsc
        disableOverlayClose
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: "#000000",
          },
        }}
      />
      <div className="flex flex-col w-full p-8 mt-5 bg-white rounded-md shadow lg:mr-4 gap-y-8 md:mt-0">
        <div className="flex flex-row justify-between w-full step-4">
          <div className="text-sm md:text-base">
            Due Date ({readCurrentUserAuth?.accountDisplaySetting?.timeZone})
          </div>
          <div className="text-sm md:text-base">
            {readAppDashboardDataLoading
              ? "Loading..."
              : !valueIsNullOrUndefined(
                  readAppDashboardData?.outstandingAmount?.currentStatementDue,
                ) &&
                readAppDashboardData?.latestCycle?.endDt &&
                readCurrentUserAuth?.fuelCard?.creditCycleType
              ? calculateDueDate(readAppDashboardData?.latestCycle?.endDt)
              : "-"}
            <DueDateComponent />
          </div>
        </div>
        <div className="flex flex-row justify-between w-full text-sm md:text-base step-3">
          <div className="">Amount Due</div>
          <div className="">
            {readAppDashboardDataLoading
              ? "Loading..."
              : !valueIsNullOrUndefined(
                  readAppDashboardData?.outstandingAmount
                    ?.outstandingAmountAfterWalletDeduction,
                ) &&
                !valueIsNullOrUndefined(
                  readAppDashboardData?.outstandingAmount?.currentStatementDue,
                )
              ? dollarFormatter(
                  (readAppDashboardData?.outstandingAmount
                    ?.outstandingAmountAfterWalletDeduction || 0) / 100,
                )
              : "-"}
          </div>
        </div>
        <div className="flex flex-row justify-between w-full text-sm md:text-base step-2">
          <div className="">
            Latest Statement (
            {readCurrentUserAuth?.accountDisplaySetting?.timeZone})
          </div>
          <div className="">
            {readAppDashboardDataLoading
              ? "Loading..."
              : readAppDashboardData?.latestCycle
              ? `${convertISOStringToDateNew(
                  readAppDashboardData.latestCycle.startDt?.toString(),
                  false,
                  false,
                  true,
                  tz,
                )}
                
                - ${convertISOStringToDateNew(
                  readAppDashboardData.latestCycle.endDt?.toString(),
                  false,
                  false,
                  true,
                  tz,
                )}`
              : "-"}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between w-full text-sm md:text-base">
          <div>
            <div className="">Download Multiple Statements</div>
            <Calendar
              id="range"
              value={dates}
              onChange={onDateRangeChange}
              selectionMode="range"
              placeholder="Select Date Range"
              className="custom-calendar"
              panelClassName="custom-calendar-panel"
              maxDate={new Date()}
              maxDateCount={30}
              minDate={minDate}
              inputClassName="!py-2"
              readOnlyInput
            />
          </div>
          {multipleDownloadData.length > 0 ? (
            <StatementMultipleDownloads
              setMultipleDownloadData={setMultipleDownloadData}
              multipleDownloadData={multipleDownloadData}
              setShowExportModal={setShowExportModal}
              setShowLoading={setShowLoading}
              setShowComplete={setShowComplete}
            ></StatementMultipleDownloads>
          ) : (
            <Button
              variant={ButtonVariant.AppOrange}
              size={ButtonSize.AppSize}
              disabled={
                dates
                  ? (dates as Date[])[0] === null ||
                    (dates as Date[])[1] === null ||
                    (dates as Date[]).length === 0
                  : true
              }
              className="!h-fit ml-3"
              onClick={() => onMultipleStatementDownload()}
            >
              Download
            </Button>
          )}
        </div>
      </div>

      <div className="mt-8 overflow-hidden bg-white rounded-md shadow">
        <div className="p-8 font-medium bg-white step-5">
          <div className="mx-auto">
            <div className="min-w-full overflow-hidden overflow-x-auto align-middle ">
              <DataTable
                pageLinkSize={3}
                size="small"
                stripedRows
                value={billingList}
                loading={billingCycleListLoading}
                paginator
                dataKey="id"
                style={{
                  fontFamily: "Inter",
                }}
                // filters={cardsFilterConfig}
                paginatorTemplate={newPaginationTemplate}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rowsPerPageOptions={[10, 20, 50]}
                responsiveLayout="stack"
                className="min-w-full w-fit custom-table"
                emptyMessage="No available records"
                lazy
                rows={billingCycleLazyParams.rows}
                filters={billingCycleLazyParams.filters}
                sortField={billingCycleLazyParams.sortField}
                sortOrder={billingCycleLazyParams.sortOrder}
                sortMode={"single"}
                first={billingCycleLazyParams.first}
                totalRecords={totalCount || 0}
                onPage={onPageAndSortBillingCycle}
                onSort={onPageAndSortBillingCycle}
              >
                <Column
                  // filter
                  // filterElement={dateFilterTemplate}
                  sortable
                  field="startingDate"
                  // header="Statement Period (PST)"
                  header={`Statement Period (${readCurrentUserAuth?.accountDisplaySetting?.timeZone})`}
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(e) => billingPeriodTemplate(e, tz)}
                />

                <Column
                  field="invoiceId"
                  header="Invoice No."
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
                <Column
                  // filter
                  field="spendings"
                  header="Spend"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => amountTemplate(r.spendings, "+")}
                />
                <Column
                  // filter
                  field="rewards"
                  header={
                    customerType === ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE
                      ? "Discounts"
                      : "Fuel Discounts"
                  }
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => amountTemplate(r.rewards, "-")}
                  hidden={
                    customerType === ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE
                  }
                />
                <Column
                  // filter
                  field="lateFees"
                  header="Late Fees"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => amountTemplate(r.lateFees, "+")}
                  hidden={
                    customerType === ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE
                  }
                />
                <Column
                  // filter
                  header="Amount Owed"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => amountOweTemplate(r)}
                  hidden={
                    customerType === ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE
                  }
                />
                <Column
                  // filter
                  field="paymentTerms"
                  header="Billing Frequency"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-sm text-gray-500 whitespace-nowrap"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => {
                    return humanizeEnum(r.paymentTerms || "-");
                  }}
                  hidden={
                    customerType === ENUM_CUSTOMER_TYPE.CREDIT ||
                    customerType === ENUM_CUSTOMER_TYPE.CREDIT_PLUS
                  }
                />
                <Column
                  // filter
                  field="outstandingAmount"
                  header="Outstanding"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => amountTemplate(r.outstandingAmount, "")}
                />
                <Column
                  header="Status"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => statusTemplate(r)}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        header={
          <span className="text-base md:text-lg">
            Download Multiple Statements
          </span>
        }
        visible={showExportModal}
        style={{ fontFamily: "Inter" }}
        className="w-[95%] sm:w-3/4 lg:max-w-[750px] md:max-w-[500px]"
        footer={
          <div className="flex flex-row justify-end">
            <Button
              variant={ButtonVariant.GrayOutline}
              size={ButtonSize.Normal}
              onClick={() => {
                setShowExportModal(false);
              }}
            >
              Close
            </Button>
          </div>
        }
        closable={false}
        onHide={() => {
          setShowExportModal(false);
        }}
      >
        <div className="space-y-3 text-sm md:text-base">
          <ExportImportModalLoader
            showLoading={showLoading}
            showComplete={showComplete}
          />
        </div>
      </Dialog>

      {modal && selected && (
        <StatementsDownloadModal
          modal={modal}
          toggle={toggleModal}
          data={selected}
          email=""
        />
      )}
    </div>
  );
};

export default StatementsUI;
