import { useMutation, useQuery } from "@apollo/client";
import {
  ENUM_CREDIT_CYCLE_TYPE,
  ENUM_PLATFORM_TYPE,
  ENUM_STRIPE_CARD_PROGRAM_TYPE,
} from "@prisma/client";
import { CREATE_CARD, READ_USER_CUSTOMIZED_SETTING } from "@roadflex/graphql";
import { useReadAppCardData } from "@roadflex/react-hooks";
import { UserAuthType, UserBasicType } from "@roadflex/types";
import { useRouter } from "next/router";
import { useState } from "react";
import { Loader } from "../../loader";
import { Toast } from "../../toast-message/toast";
import { CardOrderUI } from "./card-order.ui";

type CardOrderProps = {
  readCurrentUserAuth: UserAuthType;
  readCurrentUserBasic: UserBasicType;
  embeddedCardSetting: {
    id: string;
    name: string;
  }[];
};
export const CardOrder = ({
  readCurrentUserAuth,
  readCurrentUserBasic,
  embeddedCardSetting,
}: CardOrderProps) => {
  const [submittingRequest, setsubmittingRequest] = useState(false);
  const router = useRouter();

  const settings = {
    userId: "",
    id: readCurrentUserAuth.primaryOwnerId,
    creditCycleType:
      readCurrentUserAuth.fuelCard.creditCycleType ||
      ENUM_CREDIT_CYCLE_TYPE.WEEKLY,
    creditLimit: readCurrentUserAuth.fuelCard.creditLimit || 0,
    numberOfCards: readCurrentUserAuth.fuelCard.numberOfCards || 0,
    customerType: readCurrentUserAuth.fuelCard.customerType,
    platform:
      readCurrentUserAuth?.fuelCard?.platform || ENUM_PLATFORM_TYPE.STRIPE,
  };

  const { readAppCardData, loading: readAppCardDataLoading } =
    useReadAppCardData(true, false, true, "Shortened");

  const {
    data: readUserCustomizedSettings,
    loading: readUserCustomizedSettingsLoading,
  } = useQuery(READ_USER_CUSTOMIZED_SETTING, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: { data: { email: readCurrentUserBasic?.email } },
  });

  const [createCreditCardRequest] = useMutation<{
    createCardOrder: {
      message: string;
      code: string;
    };
  }>(CREATE_CARD);

  const handleCardsOrder = (
    noOfCards: number,
    deliveryType: string,
    requireSignature: boolean,
    cardType: string,
    cardProfileId: string,
    stripeCardProgram: ENUM_STRIPE_CARD_PROGRAM_TYPE,
  ) => {
    setsubmittingRequest(true);
    createCreditCardRequest({
      variables: {
        data: {
          userId: readCurrentUserAuth.primaryOwnerId,
          noOfCards,
          deliveryType,
          requireSignature,
          cardType,
          cardProfileId,
          stripeCardProgram,
        },
      },
    })
      .then(({ data }) => {
        if (data?.createCardOrder?.code === "200") {
          Toast({ type: "success", message: data?.createCardOrder?.message });
        }
        if (
          data?.createCardOrder?.code !== "200" &&
          data?.createCardOrder?.message
        ) {
          Toast({ type: "error", message: data?.createCardOrder?.message });
        }
        setsubmittingRequest(false);
        if (cardType === "PHYSICAL") {
          router.push(`/cards`);
        } else {
          router.push("virtual-cards");
        }
      })
      .catch((err) => {
        Toast({ type: "error", message: err.message });
        setsubmittingRequest(false);
      });
  };

  if (readUserCustomizedSettingsLoading || readAppCardDataLoading) {
    return <Loader />;
  }

  return (
    <CardOrderUI
      handleCardsOrder={handleCardsOrder}
      submittingRequest={submittingRequest}
      orderedCardsLength={readAppCardData?.cards?.length}
      businessId={readAppCardData?.business?.id || ""}
      settings={settings}
      userCustomisedSettings={
        readUserCustomizedSettings?.readUserCustomizedSettings || {}
      }
      embeddedCardSetting={embeddedCardSetting}
    ></CardOrderUI>
  );
};
