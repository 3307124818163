/** @format */

import { useMutation } from "@apollo/client";
import {
  ArrowPathIcon,
  ChevronRightIcon,
  FolderOpenIcon,
} from "@heroicons/react/24/outline";
import { ENUM_ELD_ORG_NAME } from "@prisma/client";
import { SYNC_ELD_DEVICE, TELEMATICS_GET_DEVICES } from "@roadflex/graphql";
import { usePlatformDetect } from "@roadflex/react-hooks";
import {
  EldDevices,
  FleetExtended,
  GeoTabGetDevicesValues,
  MapType,
  TripDetails,
} from "@roadflex/types";
import { FormikProps } from "formik";
import { useRouter } from "next/router";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useMemo, useState } from "react";
import { JsonDataViewerModal } from "../../admin-modals";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";
import { Toast } from "../../toast-message/toast";
import EditGeoTabDeviceModal from "./edit-geotab-device.modal.ui";
import GMapsModal from "./g-maps.modal.ui";
import GeoTabDeviceTripsModal from "./geotab-device-trip-list.modal.ui";

interface EldDetailsPageUIProps {
  devices: EldDevices[];
  openGeoTabDeviceTripsModal: boolean;
  setOpenGeoTabDeviceTripsModal: (value: boolean) => void;
  openGeoTabTripDetailsModal: (rowData: EldDevices) => void;
  openGMapsModal: boolean;
  setOpenGMapsModal: (value: boolean) => void;
  setMapData: (rowData: EldDevices) => void;
  mapValues: MapType;
  tripDetailValues: TripDetails;
  orgName: string;
  setOrgName: (value: string) => void;
  database: string;
  openEditGeoTabDeviceModal: boolean;
  setOpenEditGeoTabDeviceModal: (value: boolean) => void;
  openEditGeoTabDetailsModal: (rowData: EldDevices) => void;
  editGeoTabDeviceValues: {
    geoTabDeviceId: string;
    geoTabDeviceName: string;
    vehicleId: string;
  };
  setEditGeoTabDeviceValues: (value: {
    geoTabDeviceId: string;
    geoTabDeviceName: string;
    vehicleId: string;
  }) => void;
  vehiclesList: FleetExtended[];
  getVehiclesLoading: boolean;
  setMapValues: (value: MapType) => void;
  geoTabGetDevicesForm: FormikProps<GeoTabGetDevicesValues>;
}

export const EldDetailsPageUI = ({
  devices,
  openGeoTabDeviceTripsModal,
  setOpenGeoTabDeviceTripsModal,
  openGeoTabTripDetailsModal,
  openGMapsModal,
  setOpenGMapsModal,
  setMapData,
  mapValues,
  tripDetailValues,
  orgName,
  setOrgName,
  database,
  openEditGeoTabDeviceModal,
  setOpenEditGeoTabDeviceModal,
  openEditGeoTabDetailsModal,
  editGeoTabDeviceValues,
  setEditGeoTabDeviceValues,
  vehiclesList,
  getVehiclesLoading,
  setMapValues,
  geoTabGetDevicesForm,
}: EldDetailsPageUIProps) => {
  const { isMobile } = usePlatformDetect();
  const router = useRouter();
  const [openFuelAddIn, setOpenFuelAddIn] = useState(false);

  // Fuel Add-in configuration data
  const fuelAddInData = useMemo(() => {
    return `{
      "name": "RoadFlex",
      "supportEmail": "support@roadflex.com",
      "version": "1.0.1",
      "items": [
        {
          "path": "/",
          "menuName": {
            "en": "Roadflex"
          },
          "icon": "https://storage.googleapis.com/public-external-roadflex-com/fuel_transaction_data/dist/images/icon.svg",
          "menuId": "sdkAddinsLink"
        },
        {
          "url": "https://storage.googleapis.com/public-external-roadflex-com/fuel_transaction_data/app/live/RoadFlexFuelTransactionData.html",
          "path": "sdkAddinsLink/",
          "menuName": {
            "en": "Fuel Transaction & Alerts Data"
          },
          "icon": "https://storage.googleapis.com/public-external-roadflex-com/fuel_transaction_data/dist/images/stop-start-icon.svg"
        }
      ],
      "isSigned": false
    }`;
  }, []);

  const backToEldPanel = () => {
    router.push("/eld-systems");
  };

  const { isSubmitting: geoTabGetDevicesIsSubmitting } = geoTabGetDevicesForm;

  const [syncEldDeviceFn, { loading: syncEldDeviceLoading }] = useMutation(
    SYNC_ELD_DEVICE,
    {
      refetchQueries: [TELEMATICS_GET_DEVICES],
    },
  );

  const syncEldDevice = async (orgNames: string) => {
    try {
      const res = await syncEldDeviceFn({
        variables: {
          data: {
            orgName: orgNames,
          },
        },
      });

      if (res?.data?.syncEldDevice?.code === "200") {
        Toast({ type: "success", message: res?.data?.syncEldDevice?.message });
        geoTabGetDevicesForm.handleSubmit();
      } else {
        Toast({ type: "error", message: res?.data?.syncEldDevice?.message });
      }
    } catch (err) {
      if (err instanceof Error) {
        Toast({ type: "error", message: err.message });
      } else {
        Toast({
          type: "error",
          message:
            "Reach out to our customer support team at support@roadflex.com.",
        });
      }
    }
  };

  // Template functions for DataTable
  const fleetNameTemplate = (rowData: EldDevices) => {
    return (
      <div className="flex items-center justify-between gap-2 text-sm text-brown-500 5xl:text-base whitespace-nowrap">
        <div>{rowData?.fleetName || "-"}</div>
        <Button
          variant={ButtonVariant.AppOrange}
          size={ButtonSize.Small}
          className="text-sm"
          onClick={() => openEditGeoTabDetailsModal(rowData)}
        >
          Edit
        </Button>
      </div>
    );
  };

  const showDeviceOnMapTemplate = (rowData: EldDevices) => {
    const shouldShowTripsButton = !["BOUNCIE", "FLEETR", "LINXUP"].includes(
      orgName as ENUM_ELD_ORG_NAME,
    );

    return (
      <div className="flex items-center gap-4">
        <Button
          variant={ButtonVariant.AppOrange}
          size={ButtonSize.Small}
          className="text-sm"
          onClick={() => setMapData(rowData)}
        >
          Live Location
        </Button>

        {shouldShowTripsButton && (
          <Button
            variant={ButtonVariant.AppOrange}
            size={ButtonSize.Small}
            className="text-sm"
            onClick={() => openGeoTabTripDetailsModal(rowData)}
          >
            Trips
          </Button>
        )}
      </div>
    );
  };

  const getColumns = () => {
    const standardColumns = [
      <Column
        key="id"
        sortable
        filterPlaceholder="Search by ID"
        field="id"
        header="ID"
        className="text-sm 5xl:text-base"
        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
        headerClassName="!bg-white !text-brown-500 !border-brown-500"
      />,
      <Column
        key="deviceName"
        sortable
        filterPlaceholder="Search by device name"
        field="deviceName"
        header="Name"
        className="text-sm 5xl:text-base"
        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
        headerClassName="!bg-white !text-brown-500 !border-brown-500"
      />,
      <Column
        key="fleetName"
        sortable
        filterPlaceholder="Search by vehicle number"
        field="fleetName"
        header="Map to Vehicle"
        className="text-sm 5xl:text-base"
        headerClassName="!bg-white !text-brown-500 !border-brown-500"
        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
        body={fleetNameTemplate}
      />,
    ];

    if (
      ["BOUNCIE", "FLEETR", "LINXUP"].includes(orgName as ENUM_ELD_ORG_NAME)
    ) {
      standardColumns.push(
        <Column
          key="vin"
          sortable
          field="vehicleIdentificationNumber"
          header="VIN"
          className="text-sm 5xl:text-base"
          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
          headerClassName="!bg-white !text-brown-500 !border-brown-500"
        />,
      );

      // Modify ID column header for specific providers
      if (
        orgName === ENUM_ELD_ORG_NAME.BOUNCIE ||
        orgName === ENUM_ELD_ORG_NAME.FLEETR ||
        orgName === ENUM_ELD_ORG_NAME.LINXUP
      ) {
        standardColumns[0] = (
          <Column
            key="id"
            sortable
            filterPlaceholder="Search by IMEI"
            field="id"
            header="IMEI"
            className="text-sm 5xl:text-base"
            bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
            headerClassName="!bg-white !text-brown-500 !border-brown-500"
          />
        );
      }
    } else {
      standardColumns.push(
        <Column
          key="serialNumber"
          sortable
          field="serialNumber"
          header="Serial Number"
          className="text-sm 5xl:text-base"
          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
          headerClassName="!bg-white !text-brown-500 !border-brown-500"
        />,
        <Column
          key="deviceType"
          sortable
          field="deviceType"
          header="Type"
          className="text-sm 5xl:text-base"
          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
          headerClassName="!bg-white !text-brown-500 !border-brown-500"
        />,
      );
    }

    standardColumns.push(
      <Column
        key="details"
        header="Details"
        className="text-sm 5xl:text-base"
        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
        headerClassName="!bg-white !text-brown-500 !border-brown-500"
        body={showDeviceOnMapTemplate}
      />,
    );

    return standardColumns;
  };

  const isLoading =
    syncEldDeviceLoading || getVehiclesLoading || geoTabGetDevicesIsSubmitting;

  return (
    <div className="flex-col flex flex-1 md:px-4 md:pb-4 mt-0 m-4 h-[calc(100vh-96px)]">
      <div className="">
        <div className="flex flex-col w-full rounded-md md:my-12">
          <div className="flex flex-col justify-between md:flex-row">
            <div className="flex flex-row items-center">
              <div className="">
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.Transparent}
                  onClick={backToEldPanel}
                  className="pl-0 ml-[-6px] border-0"
                >
                  <span className="flex items-center h-5">
                    <ChevronRightIcon
                      className="w-5 h-5 duration-300 ease-in transform -rotate-180"
                      aria-hidden="true"
                    />
                  </span>
                  Back
                </Button>
              </div>
              <div className="ml-4 text-xl font-bold text-brown-500 md:text-2xl">
                {orgName}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 overflow-hidden rounded-md md:mt-0">
          <div className="font-medium">
            <div className="bg-transparent">
              {/* Action buttons */}
              <div className="flex flex-row justify-end w-full">
                {orgName === ENUM_ELD_ORG_NAME.GEOTAB && (
                  <div className="flex items-center mr-4">
                    <Button
                      variant={ButtonVariant.PrimaryOutline}
                      size={ButtonSize.AppSize}
                      onClick={() => setOpenFuelAddIn(true)}
                    >
                      Fuel Add-in Code
                      <FolderOpenIcon
                        className="w-4 h-4 ml-1 text-black"
                        aria-hidden="true"
                      />
                    </Button>
                  </div>
                )}
                <div className="flex items-center gap-4 my-4">
                  <Button
                    variant={ButtonVariant.AppOrange}
                    size={ButtonSize.AppSize}
                    disabled={syncEldDeviceLoading}
                    onClick={() => syncEldDevice(orgName)}
                  >
                    {syncEldDeviceLoading ? (
                      <span className="inline-flex items-center">
                        <ArrowPathIcon
                          className="w-4 h-4 mr-2 animate-spin"
                          aria-hidden="true"
                        />
                        {"Resyncing Devices..."}
                      </span>
                    ) : (
                      <span className="inline-flex items-center">
                        {"Resync Devices"}
                      </span>
                    )}
                  </Button>
                </div>
              </div>

              {/* Unified DataTable for all ELD providers */}
              <div className="p-4 overflow-hidden bg-white rounded-md shadow md:m-0">
                <div className="font-medium">
                  <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
                    <DataTable
                      size="small"
                      stripedRows
                      loading={isLoading}
                      value={devices}
                      paginator
                      rows={10}
                      dataKey="id"
                      sortField="deviceName"
                      sortOrder={1}
                      paginatorTemplate={newPaginationTemplate}
                      pageLinkSize={isMobile() ? 1 : 5}
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rowsPerPageOptions={[10, 20, 50]}
                      responsiveLayout="stack"
                      className="custom-table"
                      emptyMessage="No available records"
                      style={{ fontFamily: "Inter" }}
                    >
                      {getColumns()}
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modals */}
          {openEditGeoTabDeviceModal && (
            <EditGeoTabDeviceModal
              openEditGeoTabDeviceModal={openEditGeoTabDeviceModal}
              setOpenEditGeoTabDeviceModal={setOpenEditGeoTabDeviceModal}
              editGeoTabDeviceValues={editGeoTabDeviceValues}
              vehiclesList={vehiclesList}
              {...geoTabGetDevicesForm}
            />
          )}

          {openGeoTabDeviceTripsModal && (
            <GeoTabDeviceTripsModal
              openGeoTabDeviceTripsModal={openGeoTabDeviceTripsModal}
              setOpenGeoTabDeviceTripsModal={setOpenGeoTabDeviceTripsModal}
              database={database}
              orgName={orgName}
              tripDetailValues={tripDetailValues}
            />
          )}

          {openGMapsModal && (
            <GMapsModal
              openGMapsModal={openGMapsModal}
              setOpenGMapsModal={setOpenGMapsModal}
              database={database}
              orgName={orgName}
              mapValues={mapValues}
              setMapValues={setMapValues}
            />
          )}

          {openFuelAddIn && (
            <JsonDataViewerModal
              open={openFuelAddIn}
              setOpen={setOpenFuelAddIn}
              data={[JSON.parse(fuelAddInData)]}
              header="Fuel Add-in"
              description="Sign in to your GeoTab account, under system settings, select Add-Ins. Copy this block of code and click save."
              mode="geotab"
            />
          )}
        </div>
      </div>
    </div>
  );
};
