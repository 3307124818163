/* eslint-disable dot-notation */
/** @format */
import { useMutation } from "@apollo/client";
import { ELD_CONNECTED, TELEMATICS_AUTHENTICATE } from "@roadflex/graphql";
import { GeoTabAuthValues } from "@roadflex/types";
import { useFormik } from "formik";

type UseGeoTabAuthenticateType = {
  initialValues: GeoTabAuthValues;
  onSubmit: (
    val: {
      eldAuthenticate: {
        sessionId: string;
      };
    } | null,
    err: Error | null,
  ) => void;
};

export const useGeoTabAuthenticate = ({
  onSubmit,
  initialValues,
}: UseGeoTabAuthenticateType) => {
  const [geoTabAuth] = useMutation<{
    eldAuthenticate: {
      sessionId: string;
    };
  }>(TELEMATICS_AUTHENTICATE, {
    refetchQueries: [ELD_CONNECTED],
  });

  const {
    handleChange,
    handleSubmit,
    values,
    isSubmitting,
    setFieldValue,
    resetForm,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      const data = {
        ...value,
      };
      try {
        // console.log("data => ", data);
        const res = await geoTabAuth({
          variables: { data },
        });
        onSubmit(res?.data || null, null);
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
    ...rest,
  };
};
