/** @format */
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_QUICKBOOK_DETAILS,
  REMOVE_QUICKBOOK_CONNECTION,
} from "@roadflex/graphql";
import { QuickBookDetailsType } from "@roadflex/types";

export const useQuickbookDetails = () => {
  const { data, refetch, ...rest } = useQuery<{
    getQuickBookDetails: {
      code: string;
      message: string;
      quickBookDetail: QuickBookDetailsType | null;
    };
  }>(GET_QUICKBOOK_DETAILS, {
    fetchPolicy: "no-cache",
  });
  return { data, refetch, ...rest };
};

export const useRemoveQuickBookConnection = () => {
  const [removeQuickBookConnectionFn, { loading }] = useMutation(
    REMOVE_QUICKBOOK_CONNECTION,
    {
      fetchPolicy: "no-cache",
      refetchQueries: [GET_QUICKBOOK_DETAILS],
    },
  );

  return { removeQuickBookConnectionFn, loading };
};
