/** @format */
import { PDFDownloadLink } from "@react-pdf/renderer";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AcknowledgmentOfTermsAndServicePdf } from "@roadflex/react-components";
import {
  CustomerTypeValue,
  CustomizedSettingType,
  UserAuthType,
  UserDetailsType,
} from "@roadflex/types";
import { dollarFormatter } from "@roadflex/web-lib";
import { FormikProps } from "formik";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { ConfirmModal } from "../../../modals";

type DetailsUIProp = FormikProps<CustomerTypeValue> & {
  readCurrentUserAuth: UserAuthType;
  upgradeConfirmModal: boolean;
  setUpgradeConfirmModal: (value: boolean) => void;
  readUserDetailsData: UserDetailsType;
  readUserCustomizedSettings: CustomizedSettingType;
};
const DetailsUI = ({
  readCurrentUserAuth,
  upgradeConfirmModal,
  setUpgradeConfirmModal,
  readUserDetailsData,
  readUserCustomizedSettings,
  handleSubmit,
  isSubmitting,
}: DetailsUIProp) => {
  const productName = "FUEL CARD";
  const customerInfo1 =
    readCurrentUserAuth?.fuelCard?.customerType?.split("_")[0] || "";
  const customerInfo2 =
    readCurrentUserAuth?.fuelCard?.customerType?.split("_")[1] || "";

  const AcknowledgementOfTermsPDF = () => (
    <AcknowledgmentOfTermsAndServicePdf {...readUserDetailsData} />
  );

  let customerTypeLabel = "BASIC";
  if (customerInfo2 === "PLUS") {
    customerTypeLabel = "PREMIUM";
  } else if (customerInfo2 === "ENTERPRISE") {
    customerTypeLabel = "ENTERPRISE";
  }
  return (
    <>
      <div className="text-sm md:text-base">
        <div className="p-4 space-y-4 bg-white rounded-md shadow md:p-8">
          <div className="font-semibold text-brown-500 md:text-lg">
            Account Plan
          </div>
          <div className="flex flex-row justify-between flex-grow">
            <div className="font-medium">
              {" "}
              {productName} | {customerInfo1} | {customerTypeLabel}
            </div>

            {customerInfo2 !== "PLUS" && customerInfo2 !== "ENTERPRISE" && (
              <Button
                type={ButtonType.Button}
                variant={ButtonVariant.AppOrange}
                size={ButtonSize.AppSize}
                onClick={() => {
                  setUpgradeConfirmModal(true);
                }}
              >
                Upgrade
              </Button>
            )}
          </div>

          {readUserCustomizedSettings?.monthlyCardFee !== null &&
            readUserCustomizedSettings?.monthlyCardFee > 0 && (
              <div className="mt-4">
                Monthly Card Fees Plan
                <div className="font-medium">
                  {dollarFormatter(
                    readUserCustomizedSettings?.monthlyCardFee / 100,
                  )}{" "}
                  per month / active card
                </div>
              </div>
            )}

          {readUserCustomizedSettings?.countriesToEnable && (
            <div className="mt-4">
              Countries Enabled On This Account
              <div className="font-medium">
                {readUserCustomizedSettings?.countriesToEnable
                  .map((x) => x.name)
                  .join()}
              </div>
            </div>
          )}
        </div>
        <div className="p-4 mt-4 space-y-2 bg-white rounded-md shadow md:p-8">
          <div className="font-semibold text-brown-500 md:text-lg">
            Referral Plan
          </div>
          <div className="font-medium text-green-700">ACTIVE</div>
          <div className="">
            You receive credit when the business you refer spends a minimum on
            gas through our platform.
          </div>
        </div>
        <div className="p-4 mt-4 space-y-2 bg-white rounded-md shadow md:p-8">
          <div className="font-semibold text-brown-500 md:text-lg">
            Fuel Discount Plan
          </div>
          <div className="font-medium text-green-700">ACTIVE</div>
          <div className="p-0">
            You receive discounts for every gallon of fuel purchased using our
            cards.
          </div>
        </div>
        <div className="p-4 mt-4 space-y-2 bg-white rounded-md shadow md:p-8">
          <div className="font-semibold text-brown-500 md:text-lg">Legal</div>
          <div className="p-0">
            These are our{" "}
            <a
              href="https://www.roadflex.com/terms-of-service"
              className="font-medium"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://www.roadflex.com/privacy-policy"
              className="font-medium"
            >
              Privacy Policy
            </a>
            .
          </div>
          <div className="p-0">
            <PDFDownloadLink
              document={<AcknowledgementOfTermsPDF />}
              fileName={`Signed Agreement.pdf`}
            >
              {({ blob, url, loading, error }) => {
                return (
                  <Button
                    type={ButtonType.Button}
                    size={ButtonSize.AppSize}
                    variant={ButtonVariant.GrayOutline}
                    disabled={loading}
                    className=""
                  >
                    <span className="font-medium">
                      Download Signed Agreement
                    </span>
                  </Button>
                );
              }}
            </PDFDownloadLink>
          </div>
        </div>
      </div>
      {upgradeConfirmModal && (
        <ConfirmModal
          title="Account upgrade"
          body="Your account will be upgraded. Confirm to proceed."
          confirm={handleSubmit}
          show={upgradeConfirmModal}
          setShow={setUpgradeConfirmModal}
          submitting={isSubmitting}
        ></ConfirmModal>
      )}
    </>
  );
};

export default DetailsUI;
